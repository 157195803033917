<template>
  <div class="source-limit-wrapper">
    <div class="source-limit-icon-wrapper">
      <gl-icon
        :height="14"
        name="info-new-gray"
        :width="14"
      />
    </div>
    <div class="source-limit-text">
      Please note: This report displays only the
      <span class="source-limit-text--highlighted"> top {{ limit }} largest transactions </span> based on the
      associated risk factors.
    </div>
  </div>
</template>

<script>
import GlIcon from '@/components/gl-icon'

export default {
  name: 'SourceLimit',
  components: {
    GlIcon,
  },
  props: {
    limit: {
      type: [Number, String],
      default: 50,
    },
  },
}
</script>
<style>
.source-limit-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  border-radius: var(--Corner-radius-L);
  background: var(--Background-Hover);
}
.source-limit-icon-wrapper {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--Border-Primary);
  background: var(--Background-Primary);
}
.source-limit-text {
  font-weight: 500;
}
.source-limit-text--highlighted {
  font-weight: 600;
}
</style>
