var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-address-info__wrapper"},[_c('SectionBlock',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"report-address-info__body-wrapper"},[_c('div',{staticClass:"report-address-info__body-top"},[_c('InfoBlock',{attrs:{"ellipsis-value":"","label":"Address","loading":_vm.loading,"value":_vm.formattedInfoData.walletAddress,"with-copy":!_vm.isPdf}}),_c('GeneralInfo',{attrs:{"has-additional-info":_vm.hasAdditionalInfo,"info":_vm.formattedInfoData,"is-pdf":_vm.isPdf,"loading":_vm.loading,"selected-token":_vm.selectedToken,"tokens-list":_vm.tokensList}})],1),_c('div',{staticClass:"report-address-info__body-middle",class:{
            'report-address-info__body-middle-split': _vm.hasAdditionalInfo,
          }},[(
              !_vm.essential || (_vm.essential && _vm.info.balance)
            )?_c('InfoBlock',{attrs:{"label":_vm.hasAdditionalInfo ? 'Balance' : 'Token Balance',"loading":_vm.loading,"rate":_vm.formattedInfoData.price,"rate-time":"current","rate-timestamp":_vm.formattedInfoData.priceTimestamp,"value":_vm.formattedInfoData.balance}}):_vm._e(),(
              !_vm.essential ||
                (_vm.essential && _vm.formattedInfoData.totalTransactions) ||
                _vm.formattedInfoData.totalTransactions
            )?_c('InfoBlock',{attrs:{"label":"Total Transactions","loading":_vm.loading,"value":_vm.formattedInfoData.totalTransactions}}):_vm._e(),(!_vm.essential)?_c('InfoBlock',{attrs:{"label":"Transactions Sent","loading":_vm.loading,"value":_vm.formattedInfoData.transactionsSent}}):_vm._e(),(!_vm.essential)?_c('InfoBlock',{attrs:{"label":"Transactions Received","loading":_vm.loading,"value":_vm.formattedInfoData.transactionsReceived}}):_vm._e(),(_vm.isPdf)?_c('div',{staticClass:"report-info-block__spacer"}):_vm._e(),(_vm.hasAdditionalInfo)?_c('InfoBlock',{attrs:{"label":"Total Received","loading":_vm.loading,"rate":_vm.formattedInfoData.totalReceivedPrice,"rate-time":"current","rate-timestamp":_vm.formattedInfoData.totalReceivedPriceTimestamp,"value":_vm.formattedInfoData.totalReceived}}):_vm._e(),(_vm.hasAdditionalInfo)?_c('InfoBlock',{attrs:{"label":"First Seen","loading":_vm.loading,"value":_vm.formattedInfoData.firstSeen}}):_vm._e(),(_vm.hasAdditionalInfo)?_c('InfoBlock',{attrs:{"label":"Total Sent","loading":_vm.loading,"rate":_vm.formattedInfoData.totalSentPrice,"rate-time":"current","rate-timestamp":_vm.formattedInfoData.totalSentPriceTimestamp,"value":_vm.formattedInfoData.totalSent}}):_vm._e(),(_vm.hasAdditionalInfo)?_c('InfoBlock',{attrs:{"label":"Last Seen","loading":_vm.loading,"value":_vm.formattedInfoData.lastSeen}}):_vm._e()],1)])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }