<template>
  <div>
    <div
      v-if="title"
      class="profile-owner__title"
    >
      {{ title }}
    </div>
    <div
      v-if="ownerData?.name || ownerData?.entityData?.name || ownerData?.owner"
      class="profile-owner__name"
    >
      <GlRowWrapper
        ellipsis
        :icon-height="16"
        :icon-name="ownerData?.entityId && !isPdf ? 'open-on-new-page-2' : ''"
        :icon-width="16"
        is-icon-link
        :value="ownerData?.name || ownerData?.entityData?.name || ownerData?.owner"
      />
    </div>
    <div
      v-if="ownerData.description"
      class="profile-owner__description"
    >
      <template
        v-if="ownerData.description && isValidURL(ownerData.description)"
      >
        <GlLink :url="ownerData.description">
          <GlRowWrapper
            :icon-height="16"
            icon-name="open-on-new-page-2"
            :icon-width="16"
            is-icon-link
          >
            <template #value>
              <div> Source link </div>
            </template>
          </GlRowWrapper>
        </GlLink>
      </template>
      <template v-else>
        {{ ownerData.description }}
      </template>
    </div>
  </div>
</template>

<script>
// Components
import GlRowWrapper from '@/components/text/gl-row-wrapper'
import GlLink from '@/components/gl-link'
// Utils
import { isValidURL } from '@/utils/text-formatter'

export default {
  name: 'ProfileOwner',
  components: { GlRowWrapper, GlLink },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    ownerData: {
      type: Object,
      default: () => ({}),
    },
    isPdf: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isValidURL,
  },
}
</script>
<style>
.profile-owner__title {
  color: var(--Content-Tertiary);
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
}
.profile-owner__name {
  color: var(--Content-Primary);
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 6px;
  width: fit-content;
}
.profile-owner__description {
  color: var(--Content-Secondary);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  max-width: 640px;
  width: fit-content;
}

.profile-owner__description .gl-link__wrapper {
  color: var(--Content-Secondary);
}
.profile-owner__description .gl-link__wrapper:visited {
  color: var(--Content-Secondary);
}
</style>
