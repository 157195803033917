<template>
  <section class="gl-section-block__wrapper">
    <div
      v-if="$slots.title"
      class="gl-section-block__title-wrapper"
    >
      <slot name="title" />
    </div>
    <div
      v-if="$slots.info"
      class="gl-section-info-wrapper"
    >
      <slot name="info" />
    </div>
    <div
      v-if="$slots.body"
      class="gl-section-block__body-wrapper"
      :class="{ 'gl-section-block__body-wrapper--white': bodyWhite }"
    >
      <slot name="body" />
    </div>
  </section>
</template>

<script>
// to delete
export default {
  name: 'Section',
  props: {
    bodyWhite: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style>
.gl-section-block__wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.gl-section-block__title-wrapper {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}
.gl-section-block__body-wrapper {
  background-color: transparent;
}
.gl-section-block__body-wrapper--white {
  background-color: var(--white);
  padding: 16px;
  border-radius: var(--Corner-radius-L);
  border: 1px solid var(--Accent-Coton-Grey);
}
@media (max-width: 767px) {
  .gl-section-block__wrapper {
    gap: 16px;
  }
  .gl-section-block__title-wrapper {
    font-size: 20px;
    font-weight: 700;
  }
}
</style>
