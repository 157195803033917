var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"type-entity-wrapper"},[_c('div',{staticClass:"type-entity-part-wrapper"},[_c('div',{staticClass:"type-entity-part-head-wrapper"},[_c('div',{staticClass:"type-entity-part-head-info-wrapper m-fs-14"},[_c('div',[(_vm.hasTotalReceived)?_c('div',{staticClass:"fs-14"},[_c('div',{staticClass:"mb-1"},[_vm._v(" Source of Funds ")]),_c('div',{staticClass:"bold-700"},[_vm._v(" "+_vm._s((_vm.hasTotalReceived && _vm.toComaSeparate( _vm.formatAmount(_vm.addressPart.data.totalReceived, true, 3), )) || '0')+" ")]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: `${_vm.priceMessage(
                  'current',
                  _vm.addressPart.data.totalReceivedPriceTimestamp,
                )}`,
                show: _vm.showTooltipPriceMessage,
                trigger: 'manual',
              }),expression:"{\n                content: `${priceMessage(\n                  'current',\n                  addressPart.data.totalReceivedPriceTimestamp,\n                )}`,\n                show: showTooltipPriceMessage,\n                trigger: 'manual',\n              }"}],staticClass:"fs-10 price",on:{"mouseleave":function($event){_vm.showTooltipPriceMessage = false},"mouseover":function($event){_vm.showTooltipPriceMessage = true}}},[_vm._v(" "+_vm._s(_vm.hasTotalReceived && _vm.formatByPrice(_vm.addressPart.data.totalReceivedPrice))+" ")])]):_vm._e()]),_c('div',{staticClass:"flex align-center gap-3"},[(_vm.entityPart.purchased)?_c('GlSelectButton',{attrs:{"options":_vm.activePartOptions},model:{value:(_vm.innerActivePart),callback:function ($$v) {_vm.innerActivePart=$$v},expression:"innerActivePart"}}):_vm._e(),_c('DropDownSort',{on:{"sortChange":_vm.sortChange}})],1),(_vm.hasTxsBySources)?_c('div',{staticClass:"fs-14"},[_c('div',{staticClass:"mb-1"},[_vm._v(" Evaluated Transactions ")]),_c('div',{staticClass:"bold-700 text-end"},[_vm._v(" "+_vm._s(_vm.toComaSeparate(_vm.addressPart.data.numberOfTxsBySources) || '0')+" ")])]):_vm._e()])]),_c('div',{ref:"typeEntityTableWrapperRef",staticClass:"type-entity-part-table-wrapper",class:{ 'type-entity-part-table-wrapper--close' : _vm.tableClose },style:(_vm.dynamicStyleForTable)},[(!_vm.resultDataSortedShare.length)?_c('div',[_vm._v(" Not found ")]):_c('div',[_c('GlScoringBarChart',{ref:"scoringBarChart",attrs:{"active-bar-item":_vm.innerActiveTypeEntityItem,"basis-percent-graph-width":_vm.basisPercentGraphWidth,"data-table":_vm.resultDataSortedShare,"pointer-item":_vm.fullDetailsPart.purchased,"separation-factor-point":_vm.separationFactorPoint},on:{"scoring-bar-item-click":_vm.scoringBarItemClick}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }