<template>
  <div class="pdf-report-bars__wrapper">
    <SectionBlock
      v-if="showIncoming"
      body-white
    >
      <template #title>
        Risk Exposure
      </template>
      <template #body>
        <div class="pdf-report-bars__bar-wrapper">
          <div class="pdf-report-bars__part-title">
            {{ getActivePartBar.name }}
          </div>
          <RiskExposureHeader
            :amount="accSofAmount"
            header="Source of Funds"
            :loading="loading"
            :price="accSofPrice"
            :transactions="uniqueTransactionsIncoming"
          />

          <gl-scoring-bar-chart
            :data-table="activeDataSourcesIncoming"
            full
            is-pdf
            :loading="loading"
          />
        </div>
      </template>
    </SectionBlock>
    <SectionBlock
      v-if="showOutgoing"
      body-white
    >
      <template #title>
        Risk Exposure
      </template>
      <template #body>
        <div class="pdf-report-bars__bar-wrapper">
          <div class="pdf-report-bars__part-title">
            {{ getActivePartBar.name }}
          </div>
          <RiskExposureHeader
            :amount="accUofAmount"
            header="Use of Funds"
            :loading="loading"
            :price="accUofPrice"
            :transactions="uniqueTransactionsOutgoing"
          />

          <gl-scoring-bar-chart
            :data-table="activeDataSourcesOutgoing"
            full
            is-pdf
            :loading="loading"
            reverse
          />
        </div>
      </template>
    </SectionBlock>
  </div>
</template>

<script>
// Components
import SectionBlock from '@/components/gl-section-block'
import GlScoringBarChart from '@/components/charts/gl-scoring-bar-chart-2'
import RiskExposureHeader from '@/pages/report/components/gl-report/bars/RiskExposureHeader'

// Vuex
import { mapGetters } from 'vuex'

export default {
  components: {
    SectionBlock,
    GlScoringBarChart,
    RiskExposureHeader,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    showIncoming: {
      type: Boolean,
      default: false,
    },
    showOutgoing: {
      type: Boolean,
      default: false,
    },
    accSofAmount: {
      type: String,
      default: '',
    },
    accUofAmount: {
      type: String,
      default: '',
    },
    accSofPrice: {
      type: [String, Number],
      default: '',
    },
    accUofPrice: {
      type: [String, Number],
      default: '',
    },
    activeDataSourcesIncoming: {
      type: Array,
      default: () => [],
    },
    activeDataSourcesOutgoing: {
      type: Array,
      default: () => [],
    },
    uniqueTransactionsIncoming: {
      type: Number,
      default: null,
    },
    uniqueTransactionsOutgoing: {
      type: Number,
      default: null,
    },

    extraPdfParams: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('directions', ['getActivePartBar']),
  },
}
</script>

<style>
.pdf-report-bars__bar-wrapper {
  width: 100%;
}
.pdf-report-bars__part-title {
  font-size: 10px;
  font-weight: 600;
}
</style>
