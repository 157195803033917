var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'gl-search-box',
    { 'gl-search-box__tagging': _vm.tagging },
    { 'gl-search-box__full': _vm.full },
    { 'gl-search-box__columns-auto': _vm.independent },
  ]},[_c('div',{staticClass:"gl-search-box__input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],class:[
        'gl-input__input',
        'gl-search-box__input',
        { 'gl-input__input--dark': _vm.dark },
        { 'gl-input__input--grey': _vm.grey },
        { 'gl-input__input--white': _vm.white },
        { 'gl-search-box__independent': _vm.independent },
      ],style:([
        { height: _vm.height },
        { minWidth: _vm.minWidth },
      ]),attrs:{"disabled":_vm.disabledField,"placeholder":_vm.placeholder,"type":"text"},domProps:{"value":(_vm.innerValue)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchValue.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.innerValue=$event.target.value}}}),_c('div',{staticClass:"flex"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.hasValue)?_c('gl-icon',{staticClass:"gl-search-box__clear-icon",class:[{ 'mr-4': _vm.independent }],attrs:{"height":24,"name":_vm.darkClear ? 'clear-large-dark' : 'clear-large',"width":24},on:{"click":_vm.clearValue}}):_vm._e()],1),(_vm.independent)?_c('div',{staticClass:"gl-search-box__independent-button",class:{ 'gl-search-box__independent-button--disables': _vm.disabled },on:{"click":_vm.searchValue}},[_c('gl-icon',{attrs:{"height":24,"name":"find","width":24}})],1):_vm._e()],1)]),(!_vm.independent)?_c('Button',{staticClass:"gl-search-box-button",attrs:{"disabled":_vm.disabled,"label":_vm.buttonText,"loading":_vm.loading},on:{"click":_vm.searchValue}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }