var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"gl-section-wrapper",class:{
    'gl-section-wrapper--green': _vm.green,
    'gl-section-wrapper--warning': _vm.warning,
  }},[_c('div',{staticClass:"gl-section-title",class:{
      'gl-section-title--green': _vm.green,
      'gl-section-title--warning': _vm.warning,
    }},[_vm._t("title")],2),_c('div',{staticClass:"gl-section-body-wrapper m-fullwidth"},[_vm._t("body")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }