<template>
  <div class="report-address-info__wrapper">
    <SectionBlock class="mb-4">
      <template #title>
        {{ title }}
      </template>
      <template #body>
        <div class="report-address-info__body-wrapper">
          <div class="report-address-info__body-top">
            <InfoBlock
              ellipsis-value
              label="Address"
              :loading="loading"
              :value="formattedInfoData.walletAddress"
              :with-copy="!isPdf"
            />
            <GeneralInfo
              :has-additional-info="hasAdditionalInfo"
              :info="formattedInfoData"
              :is-pdf="isPdf"
              :loading="loading"
              :selected-token="selectedToken"
              :tokens-list="tokensList"
            />
          </div>
          <div
            class="report-address-info__body-middle"
            :class="{
              'report-address-info__body-middle-split': hasAdditionalInfo,
            }"
          >
            <InfoBlock
              v-if="
                !essential || (essential && info.balance)
              "
              :label="hasAdditionalInfo ? 'Balance' : 'Token Balance'"
              :loading="loading"
              :rate="formattedInfoData.price"
              rate-time="current"
              :rate-timestamp="formattedInfoData.priceTimestamp"
              :value="formattedInfoData.balance"
            />
            <InfoBlock
              v-if="
                !essential ||
                  (essential && formattedInfoData.totalTransactions) ||
                  formattedInfoData.totalTransactions
              "
              label="Total Transactions"
              :loading="loading"
              :value="formattedInfoData.totalTransactions"
            />
            <InfoBlock
              v-if="!essential"
              label="Transactions Sent"
              :loading="loading"
              :value="formattedInfoData.transactionsSent"
            />
            <InfoBlock
              v-if="!essential"
              label="Transactions Received"
              :loading="loading"
              :value="formattedInfoData.transactionsReceived"
            />

            <div
              v-if="isPdf"
              class="report-info-block__spacer"
            />

            <InfoBlock
              v-if="hasAdditionalInfo"
              label="Total Received"
              :loading="loading"
              :rate="formattedInfoData.totalReceivedPrice"
              rate-time="current"
              :rate-timestamp="formattedInfoData.totalReceivedPriceTimestamp"
              :value="formattedInfoData.totalReceived"
            />
            <InfoBlock
              v-if="hasAdditionalInfo"
              label="First Seen"
              :loading="loading"
              :value="formattedInfoData.firstSeen"
            />
            <InfoBlock
              v-if="hasAdditionalInfo"
              label="Total Sent"
              :loading="loading"
              :rate="formattedInfoData.totalSentPrice"
              rate-time="current"
              :rate-timestamp="formattedInfoData.totalSentPriceTimestamp"
              :value="formattedInfoData.totalSent"
            />
            <InfoBlock
              v-if="hasAdditionalInfo"
              label="Last Seen"
              :loading="loading"
              :value="formattedInfoData.lastSeen"
            />
          </div>
        </div>
      </template>
    </SectionBlock>
  </div>
</template>

<script>
// Components
import SectionBlock from '@/components/gl-section-block'
import InfoBlock from '@/pages/report/components/info/InfoBlock'
import GeneralInfo from '@/pages/report/components/info/GeneralInfo'

export default {
  name: 'AddressInfoWrapper',
  components: {
    SectionBlock,
    InfoBlock,
    GeneralInfo,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hasAdditionalInfo: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
    essential: {
      type: Boolean,
      default: false,
    },
    selectedToken: {
      type: Object,
      default: () => ({}),
    },
    tokensList: {
      type: Array,
      default: () => [],
    },
    isPdf: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      if (this.isPdf) {
        if (this.info.type == 'address') return 'Address Risk Report'
        if (this.info.type == 'contract') return 'Smart Contract Report'
        if (this.info.type == 'tokenContract')
          return 'Token Contract Report'
        return ''
      }
      return `Address Information`
    },
    formattedInfoData() {
      return this.info.formattedData ?? {}
    },
  },
}
</script>
<style>
.report-address-info__body-wrapper {
  margin-bottom: 24px;
}
.report-address-info__body-top {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--Border-Disabled);
}
.report-address-info__body-middle {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px 30px;
}

@media (min-width: 1200px) {
  .report-address-info__body-middle-split > *:nth-child(4) {
    grid-column: 2;
    grid-row: 1;
  }
  .report-address-info__body-middle-split > *:nth-child(5) {
    grid-column: 3;
    grid-row: 1;
  }
  .report-address-info__body-middle-split > *:nth-child(6) {
    grid-column: 4;
    grid-row: 1;
  }
}

@media (min-width: 767px) and (max-width: 1200px) {
  .report-address-info__body-middle-split > *:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }
}

@media (max-width: 1200px) {
  .report-address-info__body-middle {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .report-address-info__body-middle {
    grid-template-columns: 1fr;
  }
  .report-address-info__body-middle-split > *:nth-child(6) {
    grid-column: 1;
    grid-row: 7;
  }
  .report-address-info__body-middle-split > *:nth-child(7) {
    grid-column: 1;
    grid-row: 5;
  }
}
</style>
