var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"gl-button gl-button--global",class:[
    { 'gl-button--dark': _vm.dark },
    { 'gl-button--transparent': _vm.transparent },
    { 'gl-button--full': _vm.full },
    { 'gl-button--pale-light': _vm.paleLight},
    { 'gl-button--loading': _vm.loading },
  ],style:(_vm.buttonStyle),attrs:{"disabled":_vm.disabled},on:{"click":function($event){_vm.loading ? undefined : _vm.$emit('click')}}},[(_vm.loading)?_c('div',{staticClass:"spinner-wrapper"},[_c('svg',{staticClass:"spinner",attrs:{"viewBox":"0 0 50 50"}},[_c('circle',{staticClass:"path",attrs:{"cx":"25","cy":"25","fill":"none","r":"20","stroke-width":"5"}})])]):_vm._e(),(!_vm.loading)?_vm._t("beforeTitle"):_vm._e(),(!_vm.iconOnly)?_c('span',[_vm._v(_vm._s(_vm.title))]):_vm._e(),(!_vm.loading)?_vm._t("afterTitle"):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }