<template>
  <div
    :class="classesForRiskExposureRow"
    @click="rowClick()"
  >
    <div class="risk-exposure__row-badge">
      <BadgeInfo
        :text="rowData.name"
        :value="rowData.score"
      />
      <div class="risk-exposure__row-info-values">
        <div class="risk-exposure__row-info-values-amount">
          {{ dataForAmount }}
        </div>
        <div class="risk-exposure__row-info-values-price">
          {{ dataForPrice(rowData) }}
        </div>
      </div>
    </div>
    <div class="risk-exposure__row-info">
      <div class="risk-exposure__row-info-percents">
        <div
          v-if="rowData.deepDepthShare"
          class="risk-exposure__row-info-percents-item risk-exposure__row-info-percents-item--inderect"
          :style="`width: ${calculateWidth(rowData.deepDepthShare * 100)}`"
        >
          {{ formatShare(roundShare(rowData.deepDepthShare)) }}
        </div>
        <div
          v-if="rowData.generalShare"
          class="risk-exposure__row-info-percents-item risk-exposure__row-info-percents-item--direct"
          :style="`width: ${calculateWidth(rowData.generalShare * 100)}`"
        >
          {{ formatShare(roundShare(rowData.generalShare)) }}
        </div>
      </div>
    </div>
    <div class="risk-exposure__row-cntrl">
      <img
        alt="arrow"
        class="gl-scoring-bar-chart-cntrl-arrow"
        src="@/assets/svg/redesign/arrow.svg"
      >
      <img
        alt="close"
        class="gl-scoring-bar-chart-cntrl-close"
        src="@/assets/svg/redesign/close.svg"
      >
      <!-- <GlArrow class="risk-exposure__row-cntrl-arrow" />
      <GlClose class="risk-exposure__row-cntrl-close" /> -->
    </div>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapState } from 'vuex'

// components
import BadgeInfo from '@/pages/report/components/redesign/risk-score-components/BadgeInfo'

// SVG
import GlArrow from '@/assets/svg/redesign/arrow.svg?inline'
import GlClose from '@/assets/svg/redesign/close.svg?inline'

// utils
import { formatAmount } from '@/utils/format-amount'
import { formatByPrice } from '@/utils/format-by-price'
import { toComaSeparate } from '@/utils/formatNumber'
import { formatShare, roundShare } from '@/utils/sourcesFormatter'
import { calculateRiskLevel } from '@/utils/redesign-report'

export default {
  components: {
    BadgeInfo,
    GlArrow,
    GlClose,
  },
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
    fullWidth: {
      type: Number,
      default: 0,
    },
    typeOfFunds: {
      type: String,
      default: 'sof',
    },
  },
  data() {
    return {
      percentsInPixels: 400,
      minWidth: 60,
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
    ...mapGetters('report', ['getActiveRowName']),
    classesForRiskExposureRow() {
      let classes = [`risk-exposure__row`]

      if (this.typeOfFunds == 'uof') {
        classes.push('risk-exposure__row--reverse')
      }

      if(this.getActiveRowName == this.rowData.name) {
        classes.push('risk-exposure__row--active')
      }

      classes.push(`risk-exposure__row--${this.calculateRiskLevel(this.rowData.score)}`)

      return classes
    },
    isOneBar() {
      return !this.rowData.deepDepthShare || !this.rowData.generalShare
    },
    dataForAmount() {
      return this.demoForLanding
        ? this.formatAmount(this.rowData.amount, {
            notFormatted: true,
          })
        : this.formatAmount(this.rowData.amount)
    },
  },
  methods: {
    formatAmount,
    formatByPrice,
    toComaSeparate,
    formatShare,
    roundShare,
    calculateRiskLevel,
    dataForPrice(item) {
      return this.demoForLanding
        ? this.formatByPrice(
            this.coinData.family === 'eth'
              ? (item.amount / 100000000) * 3005
              : item.price,
          )
        : this.formatByPrice(item.price)
    },
    calculateWidth(percent) {
      const doubleMinWidth = this.isOneBar
        ? this.minWidth * 2 + 2
        : this.minWidth
      const percentWidth = Math.round(
        (this.percentsInPixels / this.fullWidth) * percent,
      )

      const finalWidth =
        percentWidth >= this.minWidth
          ? this.isOneBar
            ? percentWidth + this.minWidth
            : percentWidth
          : doubleMinWidth
      return `${finalWidth}px`
    },
    rowClick() {
      this.$emit('row-click')
    },
  },
}
</script>

<style scoped>
.risk-exposure__row {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid var(--pale-blue);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.4s ease;
}
.risk-exposure__row:first-child {
  border-top: 1px solid var(--pale-blue);
}
.risk-exposure__row-badge {
  flex: 0 0 calc(50% - 44px);
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: calc(50% - 44px);
}
.risk-exposure__row-info {
  flex: 0 0 calc(50% + 44px);
  max-width: calc(50% + 44px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding-left: 44px;
  position: relative;
  left: 0;
  transition: left 0.4s ease;
  z-index: 2;
}
.risk-exposure__row-info-values {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 4px;
  white-space: nowrap;
}
.risk-exposure__row-info-values-amount {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  color: var(--dark);
}
.risk-exposure__row-info-values-price {
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2;
  color: var(--light-grey-59);
}
.risk-exposure__row-info-percents {
  display: flex;
  align-items: center;
  gap: 2px;
  max-width: 100%;
  font-size: 12px;
}
.risk-exposure__row-info-percents-item {
  max-width: 100% !important;
  padding: 6px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.3;
  color: var(--dark);
}

/* control */
.risk-exposure__row-cntrl {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
  cursor: pointer;
}
.risk-exposure__row-cntrl-arrow {
  display: block;
}
.risk-exposure__row-cntrl-close {
  display: none;
}

/* colors */
.risk-exposure__row--low .risk-exposure__row-info-percents-item--inderect {
  background-color: rgba(226, 238, 217, 1);
}
.risk-exposure__row--low .risk-exposure__row-info-percents-item--direct {
  background-color: rgba(112, 172, 63, 1);
  color: var(--white);
}
.risk-exposure__row--medium .risk-exposure__row-info-percents-item--inderect {
  background-color: rgba(252, 245, 206, 1);
}
.risk-exposure__row--medium .risk-exposure__row-info-percents-item--direct {
  background-color: rgba(252, 214, 48, 1);
}
.risk-exposure__row--high .risk-exposure__row-info-percents-item--inderect {
  background-color: rgba(253, 219, 229, 1);
}
.risk-exposure__row--high .risk-exposure__row-info-percents-item--direct {
  background-color: rgba(241, 16, 78, 1);
  color: var(--white);
}

/* active */
.risk-exposure__row:hover,
.risk-exposure__row--active {
  background-color: rgba(248, 250, 252, 1);
}
.risk-exposure__row:hover .risk-exposure__row-info,
.risk-exposure__row--active .risk-exposure__row-info {
  left: -28px;
}
.risk-exposure__row--active .risk-exposure__row-cntrl-arrow {
  display: none;
}
.risk-exposure__row--active .risk-exposure__row-cntrl-close {
  display: block;
}

/* reverse */
.risk-exposure__row--reverse.risk-exposure__row,
.risk-exposure__row--reverse .risk-exposure__row-info,
.risk-exposure__row--reverse .risk-exposure__row-info-percents {
  flex-direction: row-reverse;
}
.risk-exposure__row--reverse .risk-exposure__row-badge {
  align-items: end;
}
.risk-exposure__row--reverse .risk-exposure__row-info {
  padding-left: 0;
  padding-right: 44px;
}
.risk-exposure__row--reverse .risk-exposure__row-info-values {
  text-align: left;
}
.risk-exposure__row--reverse .risk-exposure__row-info-percents {
  text-align: right;
}
.risk-exposure__row--reverse .risk-exposure__row-cntrl {
  right: auto;
  left: 0;
}
.risk-exposure__row.risk-exposure__row--reverse:hover .risk-exposure__row-info,
.risk-exposure__row--reverse.risk-exposure__row--active .risk-exposure__row-info {
  left: 28px;
}
</style>
