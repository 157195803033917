export { formatter, roundShare, formatShare, formatterDepthSortValue }

/**
 * @typedef {{
 *  funds: {
 *    type?: string
 *    name: string
 *  } | {
 *    type?: string
 *    name?: string
 *  }
 * }} Funded
 */

/**
 * @template {Funded & {
 *  share: number
 *  amount: number
 *  originalShare?: number
 * }} S
 * @param {Array<S>} sources
 * @param {"funds.type"|({[k in keyof S]: S[k] extends string ? k : never }[keyof S])} prop
 */
// function formatter(sources, prop) {
//   /**
//    * @type Record<string, S>
//    */
//   const groupedMap = {},
//     { length } = sources

//   for (let s = 0; s < length; s++) {
//     const source = sources[s],
//       { funds } = source

//     if (!funds.type) funds.type = funds.name

//     /** @type {string} */
//     // @ts-expect-error Type 'undefined' is not assignable to type 'string'
//     const key = prop === 'funds.type' ? funds.type : source[prop]

//     // eslint-disable-next-line no-prototype-builtins
//     if (!groupedMap.hasOwnProperty(key)) {
//       groupedMap[key] = {
//         ...source,
//       }
//       if (!source.amountCur) groupedMap[key].amountCur = NaN
//       // if (!source.price) groupedMap[key].price = NaN
//     } else {
//       groupedMap[key].share += source.share
//       groupedMap[key].amount += source.amount
//       source.price
//         ? (groupedMap[key].price += source.price)
//         : ((groupedMap[key].price += NaN), (groupedMap[key].price = NaN))
//       source.amountCur
//         ? (groupedMap[key].amountCur += source.amountCur)
//         : ((groupedMap[key].amountCur += NaN),
//           (groupedMap[key].amountCur = NaN))
//     }
//   }

//   const grouped = Object.values(groupedMap)

//   for (let g = grouped.length; g--; ) {
//     const source = grouped[g]
//     source.originalShare = source.share
//     source.share = roundShare(source.share)
//   }

//   return grouped
// }

function formatter(sources = [], prop, { excludeSourceFilter = false } = {}) {
  /**
   * @type Record<string, S>
   */
  const groupedMap = {}
  , {length} = sources

  for (let s = 0; s < length; s++) {
    const source = sources[s]
    , {funds, depth} = source

    source.deepDepthShare = 0
    source.generalShare = 0

    if (!funds.type)
      funds.type = funds.name

    /** @type {string} */
    // @ts-expect-error Type 'undefined' is not assignable to type 'string'
    let key = prop === "funds.type" ? funds.type : source[prop]

    if (funds.kind === 'pending') key = 'pending'

    if (excludeSourceFilter && source.excludeFromSourceCount) {
      continue
    }

    if (!Object.prototype.hasOwnProperty.call(groupedMap, key)) {
      groupedMap[key] = {
        ...source,
      }
      if (depth === 1) {
        groupedMap[key].generalShare += source.share;
      } else {
        groupedMap[key].deepDepthShare += source.share;
      }
      if(!source.amountCur) groupedMap[key].amountCur = NaN
      if(!source.price) groupedMap[key].price = NaN

      if (key === 'pending') {
        groupedMap[key].funds.default = false
        groupedMap[key].funds.score = -2
        groupedMap[key].funds.type = 'Pending'
        groupedMap[key].owner = 'Pending'
      }
      
    } else {
      groupedMap[key].share += source.share
      groupedMap[key].amount += source.amount
      source.price ? groupedMap[key].price += source.price : (groupedMap[key].price += NaN, groupedMap[key].price = NaN)
      source.amountCur ? groupedMap[key].amountCur += source.amountCur : (groupedMap[key].amountCur += NaN, groupedMap[key].amountCur = NaN)

      if (depth === 1) {
        groupedMap[key].generalShare += source.share;
        groupedMap[key].deepDepthShare += 0;
      } else {
        groupedMap[key].deepDepthShare += source.share;
        groupedMap[key].generalShare += 0;
      }
    }
  }

  let grouped = Object.values(groupedMap)

  for (let g = grouped?.length; g--;) {
    const source = grouped[g]
    source.originalShare = source.share
    source.share = roundShare(source.share)
  }

  if (grouped.length == 1 && !excludeSourceFilter) {
    grouped = grouped.map(e => {
      return {
        ...e,
        deepDepthShare: e.generalShare == 0 ? 1 : e.deepDepthShare,
        generalShare: e.deepDepthShare == 0 ? 1 : e.generalShare,
      }
    }).filter(e => e);
  }

  return grouped
}

/**
 * @param {number} share
 */
function roundShare(share) {
  return Math.trunc(share * 10000) / 10000
}
/**
 * @param {number} share
 */
function formatShare(share) {
  return `${share < 0.0001 ? '<0.01' : (share * 100).toFixed(2)}%`
}

function formatterDepthSortValue(value) {
  if (typeof value === 'number') {
    return value
  }

  if (value && value.minimum && value.maximum) {
    return value.minimum
  } else {
    return null
  }
}
