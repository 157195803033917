var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-lock-section-wrapper",class:[
    {
      'report-lock-section-owner': _vm.owner,
      'report-lock-section-score': _vm.score,
      'report-lock-section-type': _vm.type,
      'report-lock-section-entity': _vm.entity,
      'report-lock-section-full': _vm.full,
    },
  ]},[_c('transition-group',{attrs:{"name":"fade-leave"}},[(_vm.loadingSection || _vm.partData.purchased)?_c('div',{key:"loader",staticClass:"flex align-center justify-center full-height"},[_c('gl-loader',{attrs:{"progress-spinner":""}})],1):(!_vm.partData.purchased)?_c('div',{key:"section",staticClass:"flex column align-center justify-center full-height"},[(_vm.text)?_c('div',{staticClass:"fs-18 line-height-27 text-center bold-600 mb-4 m-fs-16 ml-3 mr-3",style:([{ maxWidth: `${_vm.maxTextWidth}` }])},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),_c('div',[(_vm.showBuyButton)?_c('GlButtonPrime',{staticClass:"lock-report-section-button min-w-200 bold-600",class:{
            'lock-report-section-button-hover p-button-success': _vm.showHoverOnButton,
            'p-button-warning':
              _vm.partData.reportPartName == 'full' ||
              _vm.currentHoveredPart.reportPartName == 'full',
          },attrs:{"disabled":_vm.typeOfTag === 'failure'},on:{"click":function($event){return _vm.$emit('button-click', _vm.partData)},"mouseleave":function($event){return _vm.onMouseleaveButton(_vm.partData)},"mouseover":function($event){return _vm.onMouseoverButton(_vm.partData)}}},[_c('transition-group',{attrs:{"name":"fade-leave"}},[(_vm.showHoverOnButton)?_c('div',{key:_vm.partData.reportPartName + 'Included',staticClass:"flex justify-center"},[_c('gl-icon',{staticClass:"mr-2",attrs:{"height":20,"name":"done","white":"","width":20}}),_vm._v(" Included ")],1):_c('div',{key:_vm.partData.reportPartName + 'thunder',staticClass:"flex justify-center"},[_vm._v(" "+_vm._s(`Unlock for ${_vm.partData.price || ''}`)+" "),(!_vm.partData.purchased)?_c('gl-icon',{staticClass:"ml-1 mr-2",attrs:{"height":20,"name":"lookups-white","width":20}}):_vm._e()],1)])],1):_vm._e()],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }