var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{attrs:{"id":"report"}},[_c('transition-group',{attrs:{"appear":"","name":"fade"}},[_c('div',{key:"Address information"},[_c('TopAddressActions',{staticClass:"report-top-actions__wrapper",attrs:{"calculation-loading":_vm.reportPartsLoading,"info":_vm.addressPart,"loading":_vm.reportPartsLoading}}),_c('AddressInfoWrapper',{attrs:{"has-additional-info":_vm.addressPart?.formattedData?.coinFamily == 'btc',"info":_vm.addressPart,"loading":_vm.reportPartsLoading}}),_c('BottomInfoActions',{staticClass:"mb-4",attrs:{"enable-download-items":_vm.enableDownloadItems,"loading":_vm.reportPartsLoading,"report-type":"address"},on:{"export-report-to-csv":function($event){return _vm.$emit('export-report-to-csv')},"on-reload-report-click":function($event){return _vm.$emit('on-reload-report-click')}}})],1),_c('GlSection',{key:"Profile",staticClass:"mb-4",attrs:{"green":_vm.showHoverOnSectionOwner,"warning":_vm.showHoverOnSectionFull},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(" Profile ")])]},proxy:true},{key:"body",fn:function(){return [_c('transition-group',{attrs:{"name":"fade-leave"}},[(
                !_vm.ownerPart.purchased ||
                  _vm.startAddressCheckLoading ||
                  _vm.reportPartsLoading
              )?_c('LockReportSection',{key:"owner-lock",attrs:{"current-hovered-part":_vm.currentHoveredPart,"loading-section":_vm.startAddressCheckLoading,"owner":"","part-data":_vm.ownerPart,"show-buy-button":false,"text":"Included with any next report parts"},on:{"button-click":part => _vm.$emit('click-unlock-now', part),"update-hovered-part":part => _vm.$emit('update-hovered-part', part)}}):(_vm.ownerPart.purchased)?_c('ProfileInfo',{key:"owner-info",attrs:{"profile-info":_vm.ownerPart.data}}):_vm._e()],1)]},proxy:true}])}),_c('GlSection',{key:"Risk score",staticClass:"mb-4",attrs:{"green":_vm.showHoverOnSectionScore,"warning":_vm.showHoverOnSectionFull},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Risk Score ")]},proxy:true},{key:"body",fn:function(){return [_c('transition-group',{attrs:{"name":"fade-leave"}},[(
                !_vm.scorePart.purchased ||
                  _vm.startAddressCheckLoading ||
                  _vm.reportPartsLoading
              )?_c('LockReportSection',{key:"RiskScoreLock",attrs:{"current-hovered-part":_vm.currentHoveredPart,"loading-section":_vm.startAddressCheckLoading,"part-data":_vm.scorePart,"score":"","text":"A number showing how risky the sources contributing to an address or transaction are."},on:{"button-click":part => _vm.$emit('click-unlock-now', part),"update-hovered-part":part => _vm.$emit('update-hovered-part', part)}}):(_vm.scorePart.purchased)?_c('div',{key:"RiskScore",staticClass:"flex justify-center"},[_c('ScoreChartWrapper',{attrs:{"has-funds-data":_vm.showScoreInChart,"incoming-sources":_vm.scorePart.data.sof,"loading":_vm.calculationLoading,"outgoing-sources":_vm.scorePart.data.uof,"profile":_vm.profileInfo2,"score":_vm.scorePart.data.totalScore}})],1):_vm._e()],1)]},proxy:true}])}),_c('GlSection',{key:"Type Entity Full",staticClass:"mb-4",attrs:{"green":_vm.showHoverOnSectionType,"warning":_vm.showHoverOnSectionFull},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.typeEntityFullSectionText)+" ")]},proxy:true},{key:"body",fn:function(){return [(
              !_vm.typesPart.purchased ||
                _vm.startAddressCheckLoading ||
                _vm.reportPartsLoading
            )?_c('LockReportSection',{attrs:{"current-hovered-part":_vm.currentHoveredPart,"loading-section":_vm.startAddressCheckLoading,"max-text-width":"350px","part-data":_vm.typesPart,"text":"Counterparties from which the address has received funds by type.","type":""},on:{"button-click":part => _vm.$emit('click-unlock-now', part),"update-hovered-part":part => _vm.$emit('update-hovered-part', part)}}):_c('ReportBarsWrapper',{attrs:{"all-data-source":_vm.allDataSource,"all-data-source-by-owner":_vm.allDataSourceByOwner,"essential":_vm.essential,"loading":_vm.calculationLoading,"pure-sources":_vm.pureSources,"selected-token":_vm.selectedToken}})]},proxy:true}])}),_c('div',{key:"full"},[(!_vm.fullDetailsPart.purchased || _vm.startAddressCheckLoading)?_c('LockReportSection',{attrs:{"current-hovered-part":_vm.currentHoveredPart,"full":"","loading-section":_vm.startAddressCheckLoading,"max-text-width":"600px","part-data":_vm.fullDetailsPart,"text":"Information about the address owner, risk score, source of funds, and transactions in a single report."},on:{"button-click":part => _vm.$emit('click-unlock-now', part),"update-hovered-part":part => _vm.$emit('update-hovered-part', part)}}):_c('ReportTablesWrapper',{attrs:{"currencies-by-direction":_vm.currenciesByDirection,"current-score-error-message":_vm.currentScoreErrorMessage,"essential":_vm.essential,"loading":_vm.calculationLoading,"pure-sources":_vm.pureSources,"report-type":"address","selected-token":_vm.selectedToken}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }