<template>
  <div>
    <div id="report">
      <transition-group
        appear
        name="fade"
      >
        <div key="Address information">
          <TopAddressActions
            :calculation-loading="reportPartsLoading"
            class="report-top-actions__wrapper"
            :info="addressPart"
            :loading="reportPartsLoading"
          />
          <AddressInfoWrapper
            :has-additional-info="addressPart?.formattedData?.coinFamily == 'btc'"
            :info="addressPart"
            :loading="reportPartsLoading"
          />

          <BottomInfoActions
            class="mb-4"
            :enable-download-items="enableDownloadItems"
            :loading="reportPartsLoading"
            report-type="address"
            @export-report-to-csv="$emit('export-report-to-csv')"
            @on-reload-report-click="$emit('on-reload-report-click')"
          />
        </div>
      
        <GlSection
          key="Profile"
          class="mb-4"
          :green="showHoverOnSectionOwner"
          :warning="showHoverOnSectionFull"
        >
          <template #title>
            <div> Profile </div>
          </template>
          <template #body>
            <transition-group name="fade-leave">
              <LockReportSection
                v-if="
                  !ownerPart.purchased ||
                    startAddressCheckLoading ||
                    reportPartsLoading
                "
                key="owner-lock"
                :current-hovered-part="currentHoveredPart"
                :loading-section="startAddressCheckLoading"
                owner
                :part-data="ownerPart"
                :show-buy-button="false"
                text="Included with any next report parts"
                @button-click="part => $emit('click-unlock-now', part)"
                @update-hovered-part="
                  part => $emit('update-hovered-part', part)
                "
              />
              <ProfileInfo
                v-else-if="ownerPart.purchased"
                key="owner-info"
                :profile-info="ownerPart.data"
              />
            </transition-group>
          </template>
        </GlSection>

        <GlSection
          key="Risk score"
          class="mb-4"
          :green="showHoverOnSectionScore"
          :warning="showHoverOnSectionFull"
        >
          <template #title>
            Risk Score
          </template>

          <template #body>
            <transition-group name="fade-leave">
              <LockReportSection
                v-if="
                  !scorePart.purchased ||
                    startAddressCheckLoading ||
                    reportPartsLoading
                "
                key="RiskScoreLock"
                :current-hovered-part="currentHoveredPart"
                :loading-section="startAddressCheckLoading"
                :part-data="scorePart"
                score
                text="A number showing how risky the sources contributing to an address or transaction are."
                @button-click="part => $emit('click-unlock-now', part)"
                @update-hovered-part="
                  part => $emit('update-hovered-part', part)
                "
              />
              <div
                v-else-if="scorePart.purchased"
                key="RiskScore"
                class="flex justify-center"
              >
                <ScoreChartWrapper
                  :has-funds-data="showScoreInChart"
                  :incoming-sources="scorePart.data.sof"
                  :loading="calculationLoading"
                  :outgoing-sources="scorePart.data.uof"
                  :profile="profileInfo2"
                  :score="scorePart.data.totalScore"
                />
              </div>
            </transition-group>
          </template>
        </GlSection>

        <GlSection
          key="Type Entity Full"
          class="mb-4"
          :green="showHoverOnSectionType"
          :warning="showHoverOnSectionFull"
        >
          <template #title>
            {{ typeEntityFullSectionText }}
          </template>

          <template #body>
            <LockReportSection
              v-if="
                !typesPart.purchased ||
                  startAddressCheckLoading ||
                  reportPartsLoading
              "
              :current-hovered-part="currentHoveredPart"
              :loading-section="startAddressCheckLoading"
              max-text-width="350px"
              :part-data="typesPart"
              text="Counterparties from which the address has received funds by type."
              type
              @button-click="part => $emit('click-unlock-now', part)"
              @update-hovered-part="part => $emit('update-hovered-part', part)"
            />

            <!-- <TypesEntityPart
              v-if="showTypesEntityPart"
              :active-part="activePart"
              :active-part-options="activePartOptions"
              :active-type-entity-item="activeTypeEntityItem"
              :address-part="addressPart"
              :entity-part="entityPart"
              :full-details-part="fullDetailsPart"
              :loading="reportPartsLoading"
              :types-part="typesPart"
              @change-active-part="e => (activePart = e)"
              @change-active-type-entity-item="e => (activeTypeEntityItem = e)"
            /> -->
            <ReportBarsWrapper
              v-else
              :all-data-source="allDataSource"
              :all-data-source-by-owner="allDataSourceByOwner"
              :essential="essential"
              :loading="calculationLoading"
              :pure-sources="pureSources"
              :selected-token="selectedToken"
            />
          </template>
        </GlSection>
        <div key="full">
          <LockReportSection
            v-if="!fullDetailsPart.purchased || startAddressCheckLoading"
            :current-hovered-part="currentHoveredPart"
            full
            :loading-section="startAddressCheckLoading"
            max-text-width="600px"
            :part-data="fullDetailsPart"
            text="Information about the address owner, risk score, source of funds, and transactions in a single report."
            @button-click="part => $emit('click-unlock-now', part)"
            @update-hovered-part="part => $emit('update-hovered-part', part)"
          />
          <!-- <FullTxInfoSection
            v-else
            :active-part="activePart"
            :active-type-entity-item="activeTypeEntityItem"
            :address-part="addressPart"
            :full-details-part="fullDetailsPart"
          /> -->
          <ReportTablesWrapper
            v-else
            :currencies-by-direction="currenciesByDirection"
            :current-score-error-message="currentScoreErrorMessage"
            :essential="essential"
            :loading="calculationLoading"
            :pure-sources="pureSources"
            report-type="address"
            :selected-token="selectedToken"
          />
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
// Components
import GlSection from '@/components/gl-section'
import ReportSectionBody from '@/pages/report/components/section-components/ReportSectionBody'
import LockReportSection from '@/pages/report/components/section-components/LockReportSection'
import FullTxInfoSection from '@/pages/report/components/section-components/FullTxInfoSection'
import TypesEntityPart from '@/pages/report/components/table-components/TypesEntityPart'
import AddressInfoWrapper from '@/pages/report/components/info/AddressInfoWrapper.vue'
import TopAddressActions from '@/pages/report/components/info/TopAddressActions'
import BottomInfoActions from '@/pages/report/components/info/BottomInfoActions'
import ProfileInfo from '@/pages/report/components/profile-info/ProfileInfo'
import ReportTablesWrapper from '@/pages/report/components/gl-report/table/ReportTablesWrapper'

import RiskExposure from './redesign/RiskExposure'
import RiskExposureCntrl from './redesign/RiskExposureCntrl'
import RiskExposureBlur from './redesign/RiskExposureBlur'

// Utils
import { formatDate } from '@/utils/format-date'
import { formatByPrice } from '@/utils/format-by-price'
import { toComaSeparate } from '@/utils/formatNumber'
import { formatAmount, txsAddPlus } from '@/utils/format-amount'
import { capitalizeFirstLetter } from '@/utils/text-formatter'
import { findColorByTypeScore, hex2rgba } from '@/utils/cytoskape-ui-rules'
import {
  formatFunds,
  ownerLabelFormatter,
  isOwnerExist,
  isClusterExist,
  separateSources,
} from '@/utils/report-data-formatter'

// Vuex
import { mapGetters, mapState } from 'vuex'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'


// GL report
import ScoreChartWrapper from '@/pages/report/components/gl-report/chart/ScoreChartWrapper'
import ReportBarsWrapper from '@/pages/report/components/gl-report/bars/ReportBarsWrapper'

export default {
  components: {
    GlSection,
    ReportSectionBody,
    LockReportSection,
    TypesEntityPart,
    FullTxInfoSection,
    RiskExposure,
    RiskExposureCntrl,
    RiskExposureBlur,
    ScoreChartWrapper,
    AddressInfoWrapper,
    TopAddressActions,
    BottomInfoActions,
    ProfileInfo,
    ReportBarsWrapper,
    ReportTablesWrapper,
  },
  mixins: [deviceWidthMixin],
  props: {
    hasOnlyOneSource: {
      type: Boolean,
      default: false,
    },
    addressData: {
      type: Object,
      default: () => ({}),
    },
    allDataSource: {
      type: Object,
      default: () => [],
    },
    addressRiskySources: {
      type: Array,
      default: () => [],
    },
    addressKnownSources: {
      type: Array,
      default: () => [],
    },
    addressUnknownSources: {
      type: Array,
      default: () => [],
    },
    allDataSourceByOwner: {
      type: Object,
      default: () => [],
    },
    startAddressCheckLoading: {
      type: Boolean,
      default: false,
    },
    reportPartsLoading: {
      type: Boolean,
      default: false,
    },
    addressPart: {
      type: Object,
      default: () => ({}),
    },
    ownerPart: {
      type: Object,
      default: () => ({}),
    },
    scorePart: {
      type: Object,
      default: () => ({}),
    },
    sofTypesPart: {
      type: Object,
      default: () => ({}),
    },
    uofTypesPart: {
      type: Object,
      default: () => ({}),
    },
    sofEntityPart: {
      type: Object,
      default: () => ({}),
    },
    uofEntityPart: {
      type: Object,
      default: () => ({}),
    },
    sofTransactionsPart: {
      type: Object,
      default: () => ({}),
    },
    uofTransactionsPart: {
      type: Object,
      default: () => ({}),
    },
    fullDetailsPart: {
      type: Object,
      default: () => ({}),
    },
    partsData: {
      type: Object,
      default: () => ({}),
    },
    currentHoveredPart: {
      type: Object,
      default: () => ({}),
    },
    calculationLoading: {
      type: Boolean,
      default: false,
    },
    profileInfo2: {
      type: Object,
      default: () => ({}),
    },
    totalFunds: {
      type: [Number, String],
      default: '',
    },
    enableDownloadItems: {
      type: Array,
      Object,
      default: () => [],
    },
    entityPart: {
      type: Object,
      default: () => ({}),
    },
    typesPart: {
      type: Object,
      default: () => ({}),
    },
    essential: {
      type: Boolean,
      default: false,
    },
    pureSources: {
      type: Object,
      default: () => ({}),
    },
    selectedToken: {
      type: Object,
      default: () => ({}),
    },
    currenciesByDirection: {
      type: Object,
      default: () => ({}),
    },
    currentScoreErrorMessage: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      activeTypeEntityItem: {},
      activePart: { name: 'Risk Labels', value: 'type' },
      activePartOptions: [
        { name: 'Risk Labels', value: 'type' },
        { name: 'Owners', value: 'entity' },
      ],
    }
  },
  computed: {
    ...mapState('analytics', ['coinData', 'coinAmountInfo']),
    ...mapGetters('report', ['getActiveType', 'getActiveFunds', 'getPurchasedInfo']),
    showTypesEntityPart() {
      return (
        (this.sofTypesPart.purchased ||
          this.sofEntityPart.purchased ||
          this.fullDetailsPart.purchased) &&
        !this.startAddressCheckLoading &&
        !this.reportPartsLoading
      )
    },
    hasTxs() {
      return Boolean(
        this.addressPart &&
          this.addressPart.data &&
          this.addressPart.data.numberOfTxs,
      )
    },
    typeEntityFullSectionText() {
      if (this.sofTypesPart.purchased && this.sofEntityPart.purchased) {
        return 'Risk Exposure'
      }
      return 'Risk Exposure by types'
    },
    showHoverOnSectionIncludes() {
      return this.currentHoveredPart.includes || []
    },
    showHoverOnSectionOwner() {
      return (
        this.showHoverOnSectionIncludes.includes('owner') ||
        this.currentHoveredPart.reportPartName === 'owner'
      )
    },
    showHoverOnSectionScore() {
      return (
        this.showHoverOnSectionIncludes.includes('score') ||
        this.currentHoveredPart.reportPartName === 'score'
      )
    },
    showHoverOnSectionType() {
      return (
        this.showHoverOnSectionIncludes.includes('type') ||
        this.currentHoveredPart.reportPartName === 'type'
      )
    },
    showHoverOnSectionEntity() {
      return (
        this.showHoverOnSectionIncludes.includes('entity') ||
        this.currentHoveredPart.reportPartName === 'entity'
      )
    },
    showHoverOnSectionFull() {
      return (
        this.currentHoveredPart &&
        this.currentHoveredPart.reportPartName === 'full'
      )
    },
    dataForSofTransactions() {
      return this.sofTransactionsPart?.data?.numberOfTxsBySources
    },
    dataForUofTransactions() {
      return this.uofTransactionsPart?.data?.numberOfTxsBySources
    },
    visibilityForSof() {
      return this.getActiveType == 'riskLabels' ? this.getPurchasedInfo.sof_type : this.getPurchasedInfo.sof_entity
    },
    visibilityForUof() {
      return this.getActiveType == 'riskLabels' ? this.getPurchasedInfo.uof_type : this.getPurchasedInfo.uof_entity
    },
    showScoreInChart() {
      return this.hasTxs || this.totalFunds != null
    },
    separatedSources() {
      const { sofSources, uofSources } = this.separateSources(this.fullDetailsPart.data.sources)
      return {
        sofSources,
        uofSources,
      }
    },
  },
  methods: {
    formatDate,
    toComaSeparate,
    capitalizeFirstLetter,
    formatFunds,
    findColorByTypeScore,
    hex2rgba,
    formatAmount,
    ownerLabelFormatter,
    isOwnerExist,
    isClusterExist,
    formatByPrice,
    txsAddPlus,
    separateSources,
  },
}
</script>

<style>
.report-lock-section-address-info {
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  background-image: url('~@/assets/svg/background/report/lock-address-info-section.svg');
  height: 210px;
}
.report-progress-bar-chart {
  width: 50%;
}

/* new risk exposure */
.layout-risk-exposure {
  display: flex; gap: 16px;
  flex-wrap: wrap;
}
.layout-risk-exposure__cntrl {
  flex: 0 0 100%;
}
.layout-risk-exposure__sof,
.layout-risk-exposure__uof {
  flex: 0 0 calc(50% - 8px);
  max-width: calc(50% - 8px);
}
.layout-risk-exposure--full .layout-risk-exposure__sof,
.layout-risk-exposure--full .layout-risk-exposure__uof {
  flex: 0 0 100%;
  max-width: 100%;
}

.report-top-actions__wrapper {
  margin-bottom: 24px;
}

@media (max-width: 1023px) {
  .report-lock-section-address-info {
    background-size: 100%;
    background-size: contain;
  }
  .report-progress-bar-chart {
    width: 80%;
  }
}
@media (max-width: 767px) {
  .report-progress-bar-chart {
    width: 100%;
  }
  .report-lock-section-address-info {
    background-image: url('~@/assets/svg/background/report/lock-address-info-section-mobile.svg');
  }
}
</style>
