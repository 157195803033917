import { trancateString } from '@/utils/text-formatter'
import config from "@/utils/appConfig";
import { toComaSeparate } from "@/utils/formatNumber"
import analyticsState from '@/store/modules/analytics/state'

export const calcSourcesRiskPercent = (sources) =>
    sources.reduce((acc, curr) => {
        if (curr.funds.score >= Number(config.VUE_APP_RISK_SCORE_TRESHOLD)) {
            acc += curr.share
        }

        return acc
    }, 0)


export const formatFunds = (fund, withPercent = true) => {
    const formatted = Math.ceil(fund)

    if (withPercent) {
        return formatted === '0.00' ? '<0.01%' : formatted + '%'
    } else {
        return formatted === '0.00' ? '<0.01' : formatted
    }
}

export const getOwnerByType = (data) => {
    if (data.funds && data.funds.name && data.funds.name.toLowerCase() === 'unidentified service / exchange') {
        return data.cluster || data.owner
    }

    if (data.funds && data.funds.name && data.funds.name.toLowerCase() === 'small transactions') {
        return 'Multiple'
    }

    if (data.funds && data.funds.name && data.funds.name.toLowerCase() === 'unknown single wallet service') {
        return data.address
            ? {
                owner: trancateString(data.address, 8),
                value: data.address,
                isLink: true
            }
            : 'Multiple'
    }

    if (data.funds && data.funds.name && data.funds.name.toLowerCase() === 'unknown wallet / otc / service') {
        return data.address
            ? {
                owner: trancateString(data.address, 8),
                value: data.address,
                isLink: true
            }
            : data.owner
    }

    if (data.funds && data.funds.name && data.funds.name.toLowerCase() === 'maximum depth reached' || data.funds && data.funds.name && data.funds.name.toLowerCase() === 'small transactions') {
        return 'Multiple'
    }

    return data.owner || '--'
}

export const checkMultiple = (val) => {
    return val === null || val === 'Multiple'
}

export const ownerLabelFormatter = (addressData) => {
    if (!addressData) return 'Not identified'
    if (addressData.owner || (addressData.clusterData && addressData.clusterData.owner)) {
        if (addressData.owner === addressData.clusterData && addressData.clusterData.owner) {
            return addressData.owner
        }

        if (addressData.owner && addressData.clusterData && addressData.clusterData.owner) {
            return `${addressData.owner}, ${addressData.clusterData.owner}`
        }

        if (addressData.clusterData && addressData.clusterData.owner) {
            return addressData.clusterData.owner
        }

        if (addressData.owner) {
            return addressData.owner
        }
    }

    return 'Not identified'
}

export const isOwnerExist = (addressData) => {
    if (!addressData) return false
    if (addressData.owner ||
        addressData.description ||
        addressData.tags && addressData.tags.length || 
        addressData.type) return true
    return false
}

export const isClusterExist = (addressData) => {
    if (!addressData && !addressData.clusterData) return false
    if (addressData.clusterData &&
        addressData.clusterData?.description ||
        addressData.clusterData?.owner ||
        addressData.clusterData?.tags && addressData.clusterData?.tags.length || 
        addressData.clusterData?.type) return true
    return false
}

export const separateSources = (sources) => {
    const sofSources = []
    const uofSources = []
    sources &&
      sources.forEach(source => {
        if (source.directionUp) {
          uofSources.push(source)
        } else {
          sofSources.push(source)
        }
      })
    return { sofSources, uofSources }
}

export const getUniqAndSortedTags = array => {
    return array && array
      .map(obj => (obj.tagId ? { _id: obj.tagId, ...obj } : obj))
      .reduce((accumulator, current) => {
        if (!accumulator.some(item => item._id === current._id )) {
          accumulator.push(current)
        }
        return accumulator
      }, [])
      .sort((a, b) => {
        if (a.score == null) return 1
        if (b.score == null) return -1
        return b.score - a.score
      })
}

export const transformToTokenStructure = (tokensArray = []) =>
    tokensArray.reduce((acc, { address, decimals, icon, supported, symbol }) => {
      acc[address] = {
        address,
        decimals,
        icon,
        supported,
        symbol,
        name: symbol,
        currency: symbol,
      };
      return acc;
    }, {});
  
export const getOwnerTypesAndTags = ownerData => {
    return [
      ...(ownerData?.meta || []),
      ...(ownerData?.tags || []),
      ownerData?.type || '',
    ].filter(e => e) || []
}

export const accumulateValues = ({ dataSource, key, formatterValue = false, coinData = {}, selectedToken = {} }) => {
    const hasInvalidValue = dataSource?.some(el => !el[key])
    const totalSum = dataSource?.reduce((acc, el) => acc + (el[key] || 0), 0)
    if (hasInvalidValue) {
      return 0
    }
    return formatterValue
      ? toComaSeparate(
        formatterAmountValue(
            totalSum,
            selectedToken.decimals ?? coinData.decimals,
          !selectedToken.address ? coinData.coin ?? coinData.symbol : selectedToken.symbol,
          ),
        )
      : totalSum
  }

  export const formatterAmountValue = (amount, decimals, symbol, coinData = analyticsState.coinData, fixedVal = 8) => {
    const resSymbol = symbol || analyticsState?.coinData?.label;

    if (!amount) {
        return `0 ${resSymbol}`;
    }

    const fAmount = amount ? Number(amount) / Math.pow(10, decimals ?? (coinData?.decimals ?? 1)) : 0;

    if (fAmount > 999) {
      fixedVal = 4
    }

    const formattedAmount = Math.abs(fAmount) < 0.00000001 ? '< 0.00000001' : fAmount.toFixed(fixedVal);

    return `${formattedAmount} ${resSymbol}`;
};

export const getUniqueTxLengthFromSource = sources => {
    const uniqueTX = [
      ...new Set(
        sources?.map(source => {
          return source.directTx
        }),
      ),
    ]
    return uniqueTX.length || 0
  }

  export const filterSourceResultByEntityType = (sources, activeItem, activePartBar) => {
    return (
        sources &&
        sources.filter(row => {
          if (activePartBar === 'type') {
            if (activeItem && activeItem.funds && activeItem.funds.type) {
              return row.funds.name === activeItem.funds.type
            } else return row
          }
          if (activePartBar === 'entity') {
            if (activeItem && activeItem.owner) {
              return row.owner === activeItem.owner
            } else return row
          }
          return row
        })
      )
}