<template>
  <div class="risk-exposure-header">
    <div class="risk-exposure-header__left">
      <div class="risk-exposure-header__text">
        {{ header }}
      </div>
      <VueSkeletonLoader
        v-if="loading"
        animation="wave"
        :color="'#bec3d8'"
        :height="16"
        :radius="'2'"
        type="rect"
      />
      <div
        v-else
        class="risk-exposure-header__amount-wrapper"
      >
        <div
          v-show="amount"
          class="risk-exposure-header__amount"
        >
          {{ amount }}
        </div>
        <div
          v-show="price && !loading"
          class="risk-exposure-header__price"
          :class="{ 'risk-exposure-header__amount': !amount }"
        >
          {{ formatByPrice(price) }}
        </div>
      </div>
    </div>
    <div class="risk-exposure-header__right">
      <div
        v-if="loading || transactions"
        class="risk-exposure-header__text"
      >
        Evaluated Transactions
      </div>

      <div class="risk-exposure-header__amount flex-end">
        <VueSkeletonLoader
          v-if="loading"
          animation="wave"
          :color="'#bec3d8'"
          :height="16"
          :radius="'2'"
          type="rect"
        />
        <div v-else-if="transactions">
          {{ transactions }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Component
import VueSkeletonLoader from 'skeleton-loader-vue'
// Utils
import { formatByPrice } from '@/utils/format-by-price'

export default {
  components: {
    VueSkeletonLoader,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    price: {
      type: [String, Number],
      default: '',
    },
    amount: {
      type: [String, Number],
      default: '',
    },
    transactions: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    essential: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatByPrice,
  },
}
</script>

<style>
.risk-exposure-header {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  min-height: 56px;
}
.risk-exposure-header__left,
.risk-exposure-header__right {
  flex: 0 0 calc(50% - 8px);
}
.risk-exposure-header__right {
  text-align: right;
}
.risk-exposure-header__text {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--Accent-Dark-Grey);
}
.risk-exposure-header__price {
  margin-top: 4px;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: var(--Shadow-Mountain);
}
.risk-exposure-header__amount {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--Accent-Space-Cadet);
}

@media (max-width: 767px) {
  .risk-exposure-header {
    flex-direction: column;
  }
  .risk-exposure-header__right {
    text-align: left;
  }
  .risk-exposure-header__amount {
    display: flex;
    justify-content: flex-start;
  }
}
</style>
