var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"choose-token__wrapper"},[_c('div',{staticClass:"choose-token__list-wrapper"},_vm._l((_vm.displayTokensList),function(contract){return _c('div',{key:contract.address,staticClass:"choose-token__chips",class:[
          {
            'choose-token__chips--selected':
              _vm.selectedToken.address === contract.address,
          },
          { 'choose-token__chips--disabled': _vm.disable },
        ],on:{"click":function($event){return _vm.selectToken(contract)}}},[(contract.address === 'all')?_c('GlAvatar',{attrs:{"avatar-list":_vm.getAvatarsList,"label-in-list-key":"symbol"}}):_c('GlAvatar',{attrs:{"avatar-list":[contract],"label-in-list-key":"symbol"}}),_c('div',{staticClass:"choose-token__base-width bold-600 fs-14"},[_vm._v(" "+_vm._s(contract.symbol ? _vm.trancateString(contract.symbol, 7) : contract.symbol)+" ")]),(contract.score !== undefined && contract.score !== null)?_c('GlScoreLevelChip',{attrs:{"level":contract.score,"score":"","small":""}}):_vm._e(),(contract.balance)?_c('div',{staticClass:"fs-10 bold"},[_vm._v(" "+_vm._s(_vm.toComaSeparate( String(_vm.restrictNumberAfterComma(contract.balance, 8)), ))+" ")]):_vm._e(),(contract.price)?_c('div',{staticClass:"price fs-8"},[_vm._v(" "+_vm._s(_vm.formatByPrice(contract.price))+" ")]):_vm._e()],1)}),0),_c('div',[(_vm.tokensList.length > 1)?_c('div',{staticClass:"choose-token__assets choose-token__base-width",on:{"click":function($event){_vm.openTokensModal = true}}},[_c('div',{staticClass:"choose-token__assets-circle"},[_c('gl-icon',{attrs:{"height":16,"name":"more-ellipsis","width":16}})],1),_c('div',{staticClass:"bold-600 fs-12"},[_vm._v(" Other assets ")])]):_vm._e()])]),_c('AssetsTokenModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.openTokensModal),expression:"openTokensModal"}],attrs:{"address-hash":_vm.addressHash,"disable":_vm.disable,"request-type":_vm.requestType,"selected-token":_vm.selectedToken,"tokens-list":_vm.tokensList},on:{"close":function($event){_vm.openTokensModal = false},"select":_vm.selectToken},model:{value:(_vm.openTokensModal),callback:function ($$v) {_vm.openTokensModal=$$v},expression:"openTokensModal"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }