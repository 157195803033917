import analyticsState from "@/store/modules/analytics/state"

export const formatHash = hash => {
	let startSubStr = hash.substring(0,6)
	let endSubStr = hash.slice(-6)

	return `${startSubStr}...${endSubStr}`
}

export const trimTrailingZeros = value => {
  if (typeof value !== 'string') return value

  let match = value.match(/^([\d.]+)(\s*\D+)?$/)
  if (!match) return value

  let numberPart = match[1]
  let suffix = match[2] || ''

  let cleanedNumber = numberPart.replace(/(\.\d*?[1-9])0+$/g, '$1').replace(/\.0+$/, '')

  return cleanedNumber + suffix
}

export const getDecimalVal = (addressData, amount) => {
	const defDecimal = analyticsState?.coinData?.decimals || 0
	const decimal = addressData
		&& addressData.contractData
		&& (addressData.contractData.decimals || 0)

	return Number(amount) / Math.pow(10, decimal ?? defDecimal)
}

export const formatBtcAmount = (amount, withBtc = true, searchType = '', hasContracts = false, needDecimal = false) => {
	let contract = ''
	let del = 100000000
	let defFixedVal = 8
	let defDecimal = Math.pow(10, analyticsState?.coinData?.decimals || 0)

	const decimalsMap = {
		'trx': 1000000,
		'eth': 1000000000000000000,
		'btc': 1000000000000000000,
	}

	if (analyticsState.coinData.family === 'eth') {
		del = needDecimal ? decimalsMap[searchType] || defDecimal : 1

		// Contract
		if (hasContracts) {
			contract = hasContracts
		} else {
			contract = analyticsState.coinData.label
		}

		if (amount < 0.00000001 && !hasContracts) {
			contract = 'wei'
			defFixedVal = 0
			amount = amount * defDecimal
		}
	}

	if (!searchType) contract = analyticsState.coinData.label || 'BTC'
	if (!contract) contract = analyticsState.coinData.label

	const formattedAmount = (fAmount, fDel, fixedVal) => {
		let res = fixedVal

		if ((Math.abs(fAmount) / fDel) > 999) {
			res = 4
		}

		return Math.abs(fAmount) < 0.00000001 || (Math.abs(fAmount) / fDel) < 0.00000001 ? '< 0.00000001' : (fAmount / fDel).toFixed(res)
	}

	return amount
		? `${analyticsState.coinData.family === 'eth'
			? formattedAmount(amount, del, defFixedVal)
			: formattedAmount(amount, del, defFixedVal)} ${withBtc
			? `${contract}`
			: ''}`
		: analyticsState.coinData.family === 'eth'
			? ''
			: 0
}

export function currencyShareFormatting (amount, decimals) {
	console.log(analyticsState?.coinData)
	return amount ? Number(amount) * Math.pow(10, decimals ?? analyticsState?.coinData?.decimals ?? 1) : 0
}
