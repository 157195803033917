var render = function render(){var _vm=this,_c=_vm._self._c;return _c('gl-modal',_vm._g(_vm._b({staticClass:"assets-token-modal",attrs:{"closable-top":"","info-only":"","loading":_vm.loading || _vm.disable,"title":"Other assets"},on:{"close":_vm.onCloseModal},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"assets-token-modal__search-box-wrapper"},[_c('gl-search-box',{attrs:{"button-text":"Search","dark-clear":"","disabled":!_vm.search,"grey":"","height":"38px","independent":"","placeholder":"Search"},on:{"clear":_vm.clearSearching,"input":_vm.searchToken,"search":_vm.searchToken},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{ref:"scrollContainer",staticClass:"assets-token-modal__main-content"},[_c('div',{staticClass:"assets-token-modal__list-wrapper"},[(!_vm.tokensListResult.length && !_vm.disable)?_c('div',{staticClass:"flex justify-center align-center mt-4"},[_vm._v(" No data ")]):_c('div',_vm._l((_vm.tokensListResult),function(contract){return _c('div',{key:contract.address,staticClass:"assets-token-modal__list-item",class:[
              {
                'assets-token-modal__list-item--selected':
                  _vm.selectedToken.address === contract.address,
              },
              { 'assets-token-modal__list-item--disabled': _vm.disable },
            ],on:{"click":function($event){return _vm.selectToken(contract)}}},[_c('div',{staticClass:"flex justify-center align-center gap-1"},[(contract.address === 'all')?_c('GlAvatar',{attrs:{"avatar-list":_vm.getAvatarsList,"label-in-list-key":"symbol","src-val":contract.icon}}):_c('GlAvatar',{attrs:{"avatar-list":[contract],"label-in-list-key":"symbol"}}),_c('div',{staticClass:"bold-600"},[_vm._v(" "+_vm._s(contract.symbol ? _vm.trancateString(contract.symbol, 8) : contract.symbol)+" ")])],1),_c('div',{staticClass:"flex justify-center align-center gap-2"},[_c('div',[_c('div',{staticClass:"fs-10 bold"},[_vm._v(" "+_vm._s(_vm.toComaSeparate( String(_vm.restrictNumberAfterComma(contract.balance, 8)), ))+" ")]),_c('div',{staticClass:"price fs-8"},[_vm._v(" "+_vm._s(_vm.formatByPrice(contract.price))+" ")])]),(contract.score !== undefined && contract.score !== null)?_c('GlScoreLevelChip',{staticClass:"mr-1",attrs:{"level":contract.score,"score":"","small":""}}):_vm._e(),_c('div',[_c('gl-icon',{attrs:{"height":16,"name":"open-on-new-page","width":16},on:{"click":function($event){$event.stopPropagation();return _vm.openTokenInNewTab(contract)}}})],1)],1)])}),0)])])]},proxy:true}])},'gl-modal',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }