<template>
  <div class="swap__data">
    <div class="swap__data-deposit">
      <div class="swap__data-title">
        Deposit
      </div>
      <div class="swap__data-amount__wrapper">
        <div class="swap__data-amount">
          {{ depositData(input) }}
        </div>
        <div
          :key="inputAmountCur + inputAmountCurTime"
          v-popover:tooltip.top="
            `${priceMessage('current', inputAmountCurTime)}`
          "
          class="swap__data-price price"
        >
          {{ formatByPrice(inputAmountCur) }}
        </div>
      </div>
    </div>
    <div class="swap__data-withdraw">
      <div class="swap__data-title">
        Withdraw
      </div>
      <div class="swap__data-amount__wrapper">
        <div class="swap__data-amount">
          {{ withdrawData(output) }}
        </div>
        <div
          :key="outputAmountCur + outputAmountCurTime"
          v-popover:tooltip.top="
            `${priceMessage('current', outputAmountCurTime)}`
          "
          class="swap__data-price price"
        >
          {{ formatByPrice(outputAmountCur) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Utils
import { formatterAmountValue } from '@/utils/report-data-formatter'
import { formatByPrice, priceMessage } from '@/utils/format-by-price'
import { toComaSeparate } from '@/utils/formatNumber'
// Vuex
import { mapState } from 'vuex'

export default {
  props: {
    currencies: {
      type: Object,
      default: () => ({})
    },
    input: {
      type: Array,
      default: () => [],
    },
    output: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      inputAmountCur: null,
      inputAmountCurTime: null,
      outputAmountCur: null,
      outputAmountCurTime: null,
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
  },
  methods: {
    formatterAmountValue,
    formatByPrice,
    priceMessage,
    toComaSeparate,
    depositData(data) {
      const { choosenObj, value } = this.dataLogic(data)
      this.inputAmountCur = choosenObj.amountCur
      this.inputAmountCurTime = choosenObj.amountCurTimestamp
      return value
    },
    withdrawData(data) {
      const { choosenObj, value } = this.dataLogic(data)
      this.outputAmountCur = choosenObj.amountCur
      this.outputAmountCurTime = choosenObj.amountCurTimestamp
      return value
    },
    dataLogic(data) {
      let objWithCurrency = data.find(item => 'currency' in item)

      if (objWithCurrency) {
        return {
          choosenObj: objWithCurrency,
          value: this.toComaSeparate(
            this.formatterAmountValue(
              objWithCurrency?.amount,
              this.currencies[objWithCurrency.token ?? objWithCurrency.currency]?.decimals,
              this.currencies[objWithCurrency.token ?? objWithCurrency.currency]?.currency ||
                'Unknown Token',
            ),
          ),
        }
      } else {
        return {
          choosenObj: data[0],
          value: this.toComaSeparate(
            this.formatterAmountValue(
              data[0].amount,
              this.coinData.decimals,
              this.coinData.label,
            ),
          ),
        }
      }
    },
  },
}
</script>

<style>
.swap__data {
  display: flex;
  gap: 16px;
}
.swap__data-deposit,
.swap__data-withdraw {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--Corner-radius-XS);
  background: var(--Accent-GL-Background);
  padding: 8px;
}
.swap__data-title {
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
}
.swap__data-amount__wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
.swap__data-amount {
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
}
.swap__data-price {
  font-size: 10px;
}
.swap__data-deposit .price {
  display: inline-block;
}
</style>
