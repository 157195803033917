<template>
  <GlPageWrap>
    <div class="report-wrapper">
      <div class="report">
        <div
          class="report-back-arrow-wrapper bold accent-text-link"
          @click="$router.push({ name: 'source-of-funds' })"
        >
          <gl-icon
            class="mr-2"
            :height="18"
            name="arrow-back"
            :width="11"
          />
          <div> Back to <span class="bold-700">Source of Funds</span> </div>
        </div>
        <ChooseTokenList
          v-if="coinData.supportContracts && !reportPartsLoading"
          :address-hash="searchValue"
          class="report-token-list"
          :disable="calcLoading"
          :request-type="'address'"
          :selected-token="selectedToken"
          :tokens-list="tokensList"
          @set-token="changeToken"
        />
        <div>
          <AddressReport
            v-if="isAddressReport"
            :address-data="addressInfo"
            :address-known-sources="addressKnownSources"
            :address-part="addressPart"
            :address-risky-sources="addressRiskySources"
            :address-unknown-sources="addressUnknownSources"
            :all-data-source="allDataSource"
            :all-data-source-by-owner="allDataSourceByOwner"
            :calculation-loading="calcLoading"
            :currencies-by-direction="currenciesByDirection"
            :current-hovered-part="currentHoveredPart"
            :current-score-error-message="currentScoreErrorMessage"
            :enable-download-items="enableDownloadItems"
            :entity-part="entityPart"
            :essential="isEssentialReport"
            :full-details-part="fullDetailsPart"
            :has-only-one-source="hasOnlyOneSource"
            :owner-part="ownerPart"
            :percent="sourcesRiskPercent"
            :profile-info2="profileInfo2"
            :pure-sources="pureSources"
            :report-parts-loading="reportPartsLoading"
            :score-part="scorePart"
            :selected-token="selectedToken"
            :sof-entity-part="sofEntityPart"
            :sof-transactions-part="sofTransactionsPart"
            :sof-types-part="sofTypePart"
            :start-address-check-loading="startAddressCheckLoading"
            :total-funds="addressTotalFunds"
            :types-part="typesPart"
            :uof-entity-part="uofEntityPart"
            :uof-transactions-part="uofTransactionsPart"
            :uof-types-part="uofTypePart"
            @click-unlock-now="clickUnlockNow"
            @clickNewPurchaseButton="clickUnlockNow"
            @export-report-to-csv="generateDataForReport()"
            @on-reload-report-click="initQuery(true)"
            @update-hovered-part="updateHoveredPart"
          />
          <div
            v-else
            class="report-empty"
          >
            Report empty
          </div>
        </div>
      </div>
      <GlConfirmModal
        v-model="confirmPurchaseModal"
        :loading="confirmPurchaseModalLoading"
        @close="rejectModalPromise(true)"
        @confirm="resolveModalPromise(true)"
      >
        <template #header>
          <div class="bold-600 fs-24 pa-4">
            <div ref="confirmModalHeader" />
          </div>
        </template>
        <template #mainSlot>
          <div class="bold pa-4 pb-0">
            <div ref="confirmModalMainContent" />
          </div>
        </template>
      </GlConfirmModal>
    </div>
  </GlPageWrap>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
//Components
import GlPageWrap from '@/components/layout/gl-page-wrap'
import AddressReport from '@/pages/report/components/AddressReport'
import GlIcon from '@/components/gl-icon'
import GlConfirmModal from '@/components/gl-confirm-modal'
import ChooseTokenList from '@/pages/report/components/token-report-2/ChooseTokenList.vue'
// Utils
import { csvExportReport } from '@/utils/export-data'
import { getObjectByKey, moveElementToTop } from '@/utils/helpers'
import { formatShare, formatter } from '@/utils/sourcesFormatter'
import { findColorByTypeScore } from '@/utils/cytoskape-ui-rules'
import { tokensSorting } from '@/utils/cytoskape-ui-rules'
import { formatDate } from '@/utils/format-date'
import { formatAmount, txsAddPlus, formatterAmountValue2 } from '@/utils/format-amount'
import { toComaSeparate } from '@/utils/formatNumber'
import appConfig from '@/utils/appConfig'
import { sortingObjects, sourcesSortOrderArray } from "@/utils/sorting";
import { separateSources, transformToTokenStructure } from '@/utils/report-data-formatter'
//mixins
import confirmModalMixin from '@/assets/mixins/confirmModalMixin'
import csvExport from '@/assets/mixins/csvExport'

export default {
  components: {
    GlPageWrap,
    AddressReport,
    GlIcon,
    GlConfirmModal,
    ChooseTokenList,
  },
  mixins: [confirmModalMixin, csvExport],
  data() {
    return {
      search: '',
      confirmPurchaseModal: false,
      confirmPurchaseModalLoading: false,
      recalculateReportLoading: false,

      addressPart: {},
      ownerPart: {},
      scorePart: {},
      sofTypePart: {},
      sofEntityPart: {},
      sofTransactionsPart: {},
      uofTypePart: {},
      uofEntityPart: {},
      uofTransactionsPart: {},
      fullDetailsPart: {},
      currentHoveredPart: {},
      reportGeneratedAt: '',

      addressInfo: {},
      addressData: {},
      txInfo: {},
      isAddressReport: false,
      isEthAddressReport: false,
      isTxReport: false,
      startAddressCheckLoading: false,
      reportPartsLoading: false,
      allSources: [],
      allDataSource: {},
      allDataSourceByOwner: {},
      allDataSourceByOwnerType: [],
      addressRiskySources: [],
      addressUnknownSources: [],
      addressKnownSources: [],
      txRiskySources: [],
      txUnknownSources: [],
      txKnownSources: [],
      messages: [],
      tokensList: [],
      contractData: {},
      sourcesRiskPercent: 0,
      txRiskPercent: 0,
      addressTotalFunds: 0,
      txTotalFunds: 0,
      totalAmount: 0,
      sourcesCount: 0,
      symbol: '',
      selectedToken: {
        symbol: '',
        address: '',
      },
      searchValue: '',
      calcLoading: false,
      profileInfo2: {},
      entityPart: {},
      typesPart: {},
      isEssentialReport: false,
      pureSources: {},
      currenciesByDirection: {},
      currentScoreErrorMessage: {},
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('analytics', ['coinType', 'coinData', 'currencyList']),
    ...mapGetters('report', ['getSofTransactions', 'getUofTransactions']),
    appConfig() {
      return appConfig
    },
    hasOnlyOneSource() {
      return this.sourcesCount === 1
    },
    enableGeneratePdf() {
      return appConfig.VUE_APP_PDF_GENERATOR
    },
    hasReportData() {
      return (
        Object.keys(this.addressInfo).length !== 0 ||
        Object.keys(this.txInfo).length !== 0 ||
        this.allDataSource.length > 0
      )
    },
    addressReport() {
      return this.isAddressReport || this.isEthAddressReport
    },
    enableDownloadItems() {
      const items = []
      if (!this.reportPartsLoading) items.push('csv')
      return items
    },
  },
  async mounted() {
    await this.getMe()
    this.initQuery()
  },
  methods: {
    ...mapMutations('analytics', ['SET_COIN_TYPE', 'SET_COIN_DATA']),
    ...mapMutations({
      SET_REDIRECT_PAYMENT_MODAL: 'payment/SET_REDIRECT_PAYMENT_MODAL',
    }),
    ...mapMutations({
      SET_REPORT_INFO: 'report/SET_REPORT_INFO',
      SET_ACTIVE_TOKEN: 'report/SET_ACTIVE_TOKEN',
      SET_PURCHASED_INFO: 'report/SET_PURCHASED_INFO',
      SET_CURRENT_PRICES: 'report/SET_CURRENT_PRICES',
    }),
    ...mapActions('report', [
      'getStartAddressCheck',
      'getReportParts',
      'purchaseReports',
    ]),
    ...mapActions('user', ['getMe']),
    formatter,
    formatShare,
    tokensSorting,
    csvExportReport,
    findColorByTypeScore,
    getObjectByKey,
    formatDate,
    moveElementToTop,
    formatAmount, 
    txsAddPlus,
    toComaSeparate,
    formatterAmountValue2,
    sortingObjects,
    separateSources,
    transformToTokenStructure,
    clickUnlockNow(currentPart) {
      if (!currentPart.price && currentPart.price != 0) {
        return
      }
      if (this.userData.balance < currentPart.price) {
        this.SET_REDIRECT_PAYMENT_MODAL(true)
        return
      }
      this.confirmPurchaseModal = true
      this.$nextTick(() => {
        if (currentPart.reportPartName === 'full') {
          this.$refs.confirmModalHeader.outerHTML = '<p>Unlock Full Report?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the full report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'owner') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Owner Information?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Owner Information report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'score') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Risk Score Information?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Risk Score Information report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'sof_type') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Source of Funds by types?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Source of Funds by Type report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'sof_entity') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Source of Funds by entity?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Source of Funds by Entities report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'sof_transactions') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Source of Funds transactions?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Source of Funds Transactions report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'uof_type') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Use of Funds by types?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Source of Funds by Type report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'uof_entity') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Use of Funds by entities?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Source of Funds by Entities report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'uof_transactions') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Use of Funds transactions?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Source of Funds Transactions report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
      })
      this.confirmModalPromice()
        .then(e => {
          if (!e) return
          this.purchaseConfirmed(currentPart)
        })
        .catch(e => {
          if (!e) return
          this.confirmPurchaseModal = false
        })
    },
    updateHoveredPart(part) {
      this.currentHoveredPart = part
    },
    initQuery(reloadReport) {
      const { query } = this.$route

      if (query.type) {
        this.SET_COIN_TYPE(query.type)
        const coin = this.currencyList.find(curr => curr.key === query.type)
        this.SET_COIN_DATA(coin)
        this.selectedToken.symbol = this.coinData.symbol
      }

      this.selectedToken.symbol = this.coinData.symbol
      this.selectedToken.address = query.token || ''

      if (this.coinData.supportContracts) {
        this.selectedToken.address = query.token ?? 'all'
        if (this.selectedToken.address == 'all') {
          this.selectedToken.symbol = "All assets"
        }
      }

      if (query.address) {
        this.search = query.address
        this.searchData(query.address, reloadReport)
        return
      }

      if (query.tx) {
        this.search = query.tx
        this.searchData(query.tx)
      }
    },
    purchaseConfirmed({ reportPartName }) {
      this.confirmPurchaseModalLoading = true
      this.purchaseReports({
        address: this.search,
        coin: this.coinData?.key,
        reportPartName: reportPartName,
      })
        .then(e => {
          if (!e?.success) {
            this.$toast.add({
              severity: 'error',
              summary: 'Error Message',
              detail: e?.data?.message,
              life: 3000,
            })
            return
          }
        })
        .finally(() => {
          this.getMe()
          this.initQuery()
          this.confirmPurchaseModalLoading = false
          this.confirmPurchaseModal = false
        })
    },
    async searchData(value, reloadReport) {

      this.searchValue = value
      this.clearData()
      await this.SET_COIN_TYPE(this.coinType)

      if (
        this.$can('use', this.coinData.family) &&
        new RegExp(this.coinData.addressRegex).test(value)
      ) {
        this.isAddressReport = true
        this.startAddressCheckLoading = true
        this.reportPartsLoading = true

        await this.getStartAddressCheck({
          address: value,
          coin: this.coinData?.key,
        })
          .then(({ data, success }) => {
            this.takePurchasedInfo(data)
            this.takeCurrentPrices(data)
            if (!success) {
              this.$toast.add({
                severity: 'error',
                summary: 'Error Message',
                detail: data,
                life: 3000,
              })
              return
            }
            this.addressPart = {
              ...this.getObjectByKey(data, 'address', 'reportPartName'),
            }
            this.ownerPart = {
              ...this.getObjectByKey(data, 'owner', 'reportPartName'),
            }
            this.scorePart = {
              ...this.getObjectByKey(data, 'score', 'reportPartName'),
            }
            this.typesPart = {
              ...this.getObjectByKey(data, 'type', 'reportPartName'),
            }
            this.entityPart = {
              ...this.getObjectByKey(data, 'entity', 'reportPartName'),
            }
            this.fullDetailsPart = {
              ...this.getObjectByKey(data, 'full', 'reportPartName'),
            }
          })
          .catch(() => {
            this.reportPartsLoading = false
          })
          .finally(() => {
            this.startAddressCheckLoading = false
          })

        const params = {
          address: value,
          coin: this.coinData?.key,
          ...(reloadReport && { recalculate: true }),
          ...(this.coinData.supportContracts ? { token: this.selectedToken.address } : {})
        }

        await this.getReportParts(params)
          .then(({ success, data }) => {
            if (!success) {
              this.$toast.add({
                severity: 'error',
                summary: 'Error Message',
                detail: data.message,
                life: 3000,
              })
              return
            }
            const { parts, createdAt } = data
            
            this.addressPart = {
              ...this.addressPart,
              ...this.getObjectByKey(parts, 'address', 'type'),
            }
            this.ownerPart = {
              ...this.ownerPart,
              ...this.getObjectByKey(parts, 'owner', 'type'),
            }
            this.scorePart = {
              ...this.scorePart,
              ...this.getObjectByKey(parts, 'score', 'type'),
            }
            this.typesPart = {
              ...this.typesPart,
              ...this.getObjectByKey(parts, 'type', 'type'),
            }
            this.entityPart = {
              ...this.entityPart,
              ...this.getObjectByKey(parts, 'entity', 'type'),
            }
            this.fullDetailsPart = {
              ...this.fullDetailsPart,
              ...this.getObjectByKey(parts, 'full', 'type'),
            }

            this.setTokens(this.addressPart?.data?.tokens)
            this.formatAddressPart(this.addressPart)

            this.profileInfo2 = {
              ...this.profileInfo2,
              clusterData: { ...this.ownerPart.data.clusterData },
              ownerData: {
                owner: this.ownerPart.data.owner,
                description: this.ownerPart.data.description,
                type: this.ownerPart.data.type,
                tags: this.ownerPart.data.tags,
                meta: this.ownerPart.data.meta,
              },
              entityData: { ...this.ownerPart.data?.entityData },
            }
           
            const reportInfo = {
              createdAt,
              walletAddress: this.searchValue,
              owner: this.ownerPart?.data?.owner,
              ownerType: this.ownerPart?.data?.type,
              ownerTags: this.ownerPart?.data?.tags,
              balance: this.addressPart?.data?.balance,
              totalTxReceived: this.addressPart?.data?.totalTxReceived,
              totalTxSent: this.addressPart?.data?.totalTxSent,
              numberOfTxs: this.addressPart?.data?.numberOfTxs,
              totalReceived: this.addressPart?.data?.totalReceived,
              totalReceivedPrice: this.addressPart?.data?.totalReceivedPrice,
              totalSent: this.addressPart?.data?.totalSent,
              totalSentPrice: this.addressPart?.data?.totalSentPrice,
              firstSeen: this.addressPart?.data?.firstSeen,
              lastSeen: this.addressPart?.data?.lastSeen,
              riskScore: this.scorePart?.data?.totalScore,
              tags: this.ownerPart?.data?.type?.name
            }

            const { sofSources, uofSources } = this.separateSources(this.fullDetailsPart.data.sources)

            const sourceCurrencies = Object.values(this.fullDetailsPart.data.currencies ?? {}) 
            const currencies = transformToTokenStructure([...this.tokensList, ...sourceCurrencies ])

            this.setTxSources({ sources: sofSources, currencies }, 'incoming')
            this.setTxSources({ sources: uofSources, currencies }, 'outgoing')

            this.SET_REPORT_INFO(reportInfo)
            this.reportGeneratedAt = createdAt
            this.formatAddressPart(this.addressPart)
            this.formatOwnerPart(this.ownerPart)
          })
          .finally(() => {
            this.reportPartsLoading = false
          })

        
        if (this.$route.name === 'report') {
          const { params } = this.$route

          const replaceParams = {
            address: value, 
            type: this.coinType,
            ...(this.coinData.supportContracts ? { token: this.selectedToken.address } : {})
          }

          await this.$router
            .replace({
              name: 'report',
              query: replaceParams,
              params,
            })
            .catch(err => err)
        }
        return
      }
      this.$toast.add({
        severity: 'error',
        summary: 'Error Message',
        detail: 'Search value is not valid',
        life: 3000,
      })
    },
    clearData() {
      this.addressPart = {}
      this.ownerPart = {}
      this.scorePart = {}
      this.sofTypePart = {}
      this.sofEntityPart = {}
      this.uofTypePart = {}
      this.uofEntityPart = {}
      this.fullDetailsPart = {}
      this.currentPartDataForConfirm = {}
      this.reportGeneratedAt = ''

      this.isAddressReport = false
      this.isTxReport = false

      this.addressData = null
      this.addressInfo = {}
      this.swapSources = []
      this.allSources = []
      this.allDataSource = {}
      this.allDataSourceByOwner = {}
      this.allDataSourceByOwnerType = []
      this.addressRiskySources = []
      this.addressUnknownSources = []
      this.addressKnownSources = []
      this.txRiskySources = []
      this.txUnknownSources = []
      this.txKnownSources = []

      this.sourcesRiskPercent = 0
      this.txRiskPercent = 0
      this.addressTotalFunds = 0
      this.txTotalFunds = 0
      this.sourcesCount = 0
    },
    setTokens(tokens) {
      this.tokensList = Object.values(tokens ?? {}) 
      if (!this.tokensList.length) return
      if (this.tokensList.length > 1) {
        this.tokensList.unshift({address: 'all', symbol: 'All assets'})
      }
      this.tokensList = this.tokensSorting(this.tokensList)

      const selectedTokenIndex = this.tokensList.findIndex(el => el.address === this.selectedToken.address)
      this.moveElementToTop(this.tokensList, selectedTokenIndex)
      const allTokenIndex = this.tokensList.findIndex(el => el.address === 'all')
      this.moveElementToTop(this.tokensList, allTokenIndex)
      this.selectedToken = this.tokensList.find(e => e.address == this.selectedToken.address ) || this.tokensList.find(e => e.address == this.coinData.symbol)
      this.SET_ACTIVE_TOKEN(this.selectedToken)
    },
    formatAddressPart(addressPart) {

      const addressData = addressPart?.data ?? {}

      this.addressPart = {
        ...addressPart,
        formattedData: {
          coinKey: this.coinData.key,
          coinFamily: this.coinData.family,
          createdAt: this.reportGeneratedAt,
          walletAddress: this.searchValue,
          totalTransactions: this.toComaSeparate(this.txsAddPlus(addressData.numberOfTxs, 2000000)) || '0',
          transactionsSent: this.toComaSeparate(this.txsAddPlus(addressData.totalTxSent, 1000000)) || '0',
          transactionsReceived: this.toComaSeparate(this.txsAddPlus(addressData.totalTxReceived, 1000000)) || '0',
          balance: this.toComaSeparate(this.formatterAmountValue2({
            amount: addressData.balance,
            decimals: this.coinData.family == 'eth' ? 0 : this.selectedToken.decimals ?? this.coinData.decimals,
            symbol: this.selectedToken?.address == 'all' ? this.coinData.symbol : this.selectedToken.symbol ?? this.coinData.symbol,
          })),
          price: addressData.price,
          priceTimestamp: addressData.priceTimestamp,
          totalSent: this.toComaSeparate(this.formatterAmountValue2({
            amount: addressData.totalSent,
            decimals: this.selectedToken.decimals ?? this.coinData.decimals,
            symbol: this.selectedToken.symbol ?? this.coinData.symbol,
          })),
          totalSentPrice: addressData.totalSentPrice,
          totalSentPriceTimestamp: addressData.totalSentPriceTimestamp,
          totalReceived: this.toComaSeparate(this.formatterAmountValue2({
            amount: addressData.totalReceived,
            decimals: this.selectedToken.decimals ?? this.coinData.decimals,
            symbol: this.selectedToken.symbol ?? this.coinData.symbol,
          })),
          totalReceivedPrice: addressData.totalReceivedPrice,
          totalReceivedPriceTimestamp: addressData.totalReceivedPriceTimestamp,
          firstSeen: addressData.firstSeen ? this.formatDate(addressData.firstSeen * 1000, 'dd.MM.yyyy HH:mm') : '',
          lastSeen: addressData.firstSeen ? this.formatDate(addressData.lastSeen * 1000, 'dd.MM.yyyy HH:mm') : '',
        }
      }
    },
    formatOwnerPart(part) {
      if (!this.ownerPart.data) return

      const ownerPart = part?.data ?? {}
      const ownerData = {
            owner: ownerPart.owner,
            description: ownerPart.description,
            type: ownerPart.type,
            tags: ownerPart.tags,
            meta: ownerPart.meta,
            entityData: ownerPart.entityData,
      }

      this.ownerPart.data.ownerData = ownerData
    },
    async changeToken(token) {
      const { query } = this.$route

      this.selectedToken = token

      await this.$router
        .replace({
          name: 'report',
          query: { ...query, token: token.address },
        })
      
      this.initQuery()
    },
    takePurchasedInfo(array) {
      const purchasedInfo = array.reduce((acc, item) => {
          acc[item.reportPartName] = item.purchased
          return acc
      }, {})
      this.SET_PURCHASED_INFO(purchasedInfo)
    },
    takeCurrentPrices(array) {
      const currentPrices = array.reduce((acc, item) => {
          acc[item.reportPartName] = item.price
          return acc
      }, {})
      this.SET_CURRENT_PRICES(currentPrices)
    },
    setTxSources({sources = [], symbol = '', currencies = {},}, activeExposurePart) {
      this.symbol = symbol

      sources = sources.map((source) => {
        const localCurrency = currencies[source.token ?? source.currency]
          ? {
            ...currencies[source.token ?? source.currency],
            decimals: currencies[source.token ?? source.currency].decimals || 0
          }
          : ((source.token ?? source.currency) && appConfig.VUE_APP_ESSENTIAL_REPORT
              ? {
                name: this.coinData.label,
                currency: this.coinData.label,
                decimals: this.coinData.decimals,
                address: source.token ?? source.currency
              }
              : {}
          );

        return {
          ...source,
          share: sources.length === 1 ? 1 : source.share,
          currencyData: localCurrency,
          formattedAmount:  this.toComaSeparate(this.formatterAmountValue2({
            amount: source.amount,
            decimals: localCurrency?.decimals ?? this.coinData.decimals,
            symbol: localCurrency?.token ?? localCurrency?.currency ?? this.coinData.coin ?? this.coinData.label,
          })),
          depthSortValue: this.formatterDepthSortValue(source.depth)
        }
      })

      this.$set(this.currenciesByDirection, activeExposurePart, currencies)
      this.$set(this.pureSources, activeExposurePart, sources)

      const groupedSourcesByType = sortingObjects(formatter(sources, 'funds.type'), sourcesSortOrderArray)

      this.$set(this.allDataSource, activeExposurePart, groupedSourcesByType.map(item => ({
        ...item,
        funds: {
          ...item.funds,
          default: Boolean(item.funds.default)
        },
        key: item.funds.type,
        tooltip: `${item.funds.type} ${formatShare(item.share)}`,
        pieValue: item.share,
        value: item.share,
        itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
        formattedAmount: this.selectedToken.address === 'all' ? '' : 
         this.toComaSeparate(this.formatterAmountValue2({
            amount: item.amount,
            decimals: item?.currencyData?.decimals ?? this.coinData.decimals,
            symbol: item?.currencyData?.token || item?.currencyData?.currency || this.coinData.coin || this.coinData.symbol,
          }))
      })))

      const groupedSourcesByOwner = sortingObjects(formatter(sources, 'owner'), sourcesSortOrderArray)

      this.$set(this.allDataSourceByOwner, activeExposurePart, groupedSourcesByOwner.map(item => ({
        ...item,
        funds: {
          ...item.funds,
          default: Boolean(item.funds.default)
        },
        key: item.owner,
        tooltip: `${item.owner} ${formatShare(item.share)}`,
        pieValue: item.share,
        value: item.share,
        itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
        formattedAmount: this.selectedToken.address === 'all' ? '' : 
         this.toComaSeparate(this.formatterAmountValue2({
            amount: item.amount,
            decimals: item?.currencyData?.decimals ?? this.coinData.decimals,
            symbol: item?.currencyData?.token || item?.currencyData?.currency || this.coinData.coin || this.coinData.symbol,
          }))
      })))

      // this.gettingEntityByOwners(this.pureSources[activeExposurePart], activeExposurePart)
    },
    formatterDepthSortValue(value) {
      if (typeof value === 'number') {
        return value
      }

      if (value && value.minimum && value.maximum) {
        return value.minimum
      } else {
        return null
      }
    },
  },
}
</script>

<style>
:root {
  --report-footer-height: 68px;
}
.report-wrapper {
  display: flex;
  justify-content: center;
  padding-top: var(--header-height);
  width: 100%;
  min-height: var(--main-content-height);
  padding-bottom: calc(
    var(--report-footer-height) + var(--report-banner-height) + 10px
  );
  overflow: hidden;
}
.report {
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
}

.report-back-arrow-wrapper {
  display: inline-flex;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 30px;
  cursor: pointer;
  user-select: none;
}

.report .info-block-key {
  max-width: 200px;
}

.report__header {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--pale-blue);
}

.report-section__content-wrapper {
  display: flex;
  gap: 24px;
}
.report-block-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media screen and (max-width: 650px) {
  .report-section__content-wrapper {
    flex-direction: column;
  }
}

.report-block__header {
  align-items: center;
  display: flex;
  font-size: 24px;
  padding: 14px;
  font-weight: 600;
  border: 1px solid #fff;
  border-bottom: 0;
  border-radius: 10px 10px 0px 0px;
}

.circle-progress svg {
  width: 100%;
}

/*.circle-progress svg path {*/
/*  stroke-width: px;*/
/*}*/

.a-circle-progress-wrapper .circle-progress {
  flex-direction: column;
}

.a-circle-progress-wrapper
  .circle-progress
  .progress-content
  .inner-default-percentage {
  align-items: flex-end;
}

.report-block__header--sof {
  background: rgba(240, 242, 249, 1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 12px 0;
}

.report-token-list {
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  :root {
    --report-footer-height: 48px;
  }
  .report-back-arrow-wrapper {
    display: none;
  }
  .report-wrapper {
    padding-top: calc(var(--header-height) + 8px);
  }
}
</style>
