<template>
  <div
    v-on-clickaway="closeWindow"
    class="gl-popup-wrapper"
    :class="{'gl-popup-wrapper__adjust-dropdown': adjustDropdownWidth}"
  >
    <div
      ref="selectContainer"
      class="gl-popup-action"
    >
      <slot name="action" />
    </div>

    <transition name="fade">
      <div
        v-if="innerValue"
        ref="dropdown"
        class="gl-popup-content"
      >
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<script>
// Directives
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'GlPopup',
  mixins: [clickaway],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    adjustDropdownPosition: {
      type: Boolean,
      default: false,
    },
    adjustDropdownWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue: {
      get: function () {
        return this.value
      },
      set: function (e) {
        this.$emit('input', e)
      },
    },
  },
  watch: {
    innerValue() {
      if (this.adjustDropdownPosition) {
        this.$nextTick(() => {
          this.adjustPosition()
        })
      }
    },
  },
  mounted() {
    if (this.adjustDropdownPosition) {
      window.addEventListener('resize', this.adjustPosition)
      window.addEventListener('scroll', this.adjustPosition, true)
    }
  },
  beforeDestroy() {
    if (this.adjustDropdownPosition) {
      window.removeEventListener('resize', this.adjustPosition)
      window.removeEventListener('scroll', this.adjustPosition, true)
    }
  },
  methods: {
    closeWindow() {
      this.innerValue = false
    },

    adjustPosition() {
      if (!this.adjustDropdownPosition) return
      this.$nextTick(() => {
        const container = this.$refs.selectContainer
        const dropdown = this.$refs.dropdown

        if (!dropdown || !container) return

        const containerRect = container.getBoundingClientRect()
        const dropdownRect = dropdown.getBoundingClientRect()

        const spaceBelow = window.innerHeight - containerRect.bottom
        const spaceAbove = containerRect.top
        const spaceRight = window.innerWidth - containerRect.right
        const spaceLeft = containerRect.left

        let top, left

        if (
          spaceBelow < dropdownRect.height &&
          spaceAbove > dropdownRect.height
        ) {
          top = `${containerRect.top - dropdownRect.height}px`
        } else {
          top = `${containerRect.bottom}px`
        }

        if (spaceRight < dropdownRect.width && spaceLeft > dropdownRect.width && !this.adjustDropdownWidth) {
          left = `${containerRect.right - dropdownRect.width}px`
        } else {
          left = `${containerRect.left}px`
        }

        dropdown.style.position = 'fixed'
        dropdown.style.top = top
        dropdown.style.left = left
        if (this.adjustDropdownWidth) {
          dropdown.style.width = `${containerRect.width}px`
        }
      })
    },
  },
}
</script>

<style>
.gl-popup-wrapper.gl-popup-wrapper__adjust-dropdown {
  position: relative;
}
.gl-popup-content {
  position: absolute;
  z-index: 2;
  padding: 16px;
  border-radius: 8px;
  background: var(--Icon-White, #fff);
  box-shadow: 0px 4px 12px 0px rgba(9, 21, 64, 0.1);
  overflow: auto;
}
</style>
