import analyticsState from "@/store/modules/analytics/state"
import reportState from "@/store/modules/report/state"
import { toComaSeparate } from './formatNumber'

export const formatHash = hash => {
	let startSubStr = hash.substring(0,7)
	let endSubStr = hash.slice(-7)

	return `${startSubStr}...${endSubStr}`
}

export const formatAmount = (amount, options = {}) => {
	const {
		amountCoin = reportState.activeToken.symbol || analyticsState.coinData.family,
		amountDecimals = reportState.activeToken.decimals || analyticsState.coinData.decimals,
		comaSeparate = true,
		isRaw = true
	} = options

	function convertIfExponential(number) {
    let decimalString = number.toFixed(50)
    decimalString = decimalString.replace(/(\.0+|(?<=\.\d*[1-9])0+)$/, '')
    return parseFloat(decimalString)
	}

	function getMinimalValue(amountDecimals) {
    if (amountDecimals < 0) {
      throw new Error("Negative decimals")
    }
    let minimalValue = '0.' + '0'.repeat(amountDecimals - 1) + '1'
    
    return minimalValue
	}

	function isBitcoinFamily() {
		return amountCoin.toLowerCase() == 'btc' || amountCoin.toLowerCase() == 'ltc' || amountCoin.toLowerCase() == 'bsv'
	}

	const minimalValue = getMinimalValue(amountDecimals)
	let formattedAmount

	if (amount !== undefined && amount !== null) {
		formattedAmount = convertIfExponential(amount)

		if(isRaw || isBitcoinFamily()) {
			formattedAmount = amountDecimals ? formattedAmount / Math.pow(10, amountDecimals) : formattedAmount
		}

		if (amountDecimals && formattedAmount < 1 / Math.pow(10, amountDecimals) && amount !== 0) {
			formattedAmount = `< ${minimalValue}`
		}

		if (typeof formattedAmount === 'number' && !isNaN(formattedAmount)) {
			formattedAmount = Math.abs(formattedAmount.toFixed(8))
		}

		formattedAmount = amountCoin ? `${formattedAmount} ${amountCoin.toUpperCase()}` : formattedAmount.toString()
	}

	if(comaSeparate) {
		formattedAmount = toComaSeparate(formattedAmount)
	}

	return formattedAmount
}

export const formatterAmountValue2 = ({ amount, decimals, symbol, coinData = analyticsState.coinData, fixedVal = 8 }) => {
	
    const resSymbol = symbol || analyticsState?.coinData?.symbol;

    if (!amount) {
        return `0 ${resSymbol}`;
    }

    const fAmount = amount ? Number(amount) / Math.pow(10, decimals ?? (coinData?.decimals ?? 1)) : 0;

    if (fAmount > 999) {
      fixedVal = 4
    }

    const formattedAmount = Math.abs(fAmount) < 0.00000001 ? '< 0.00000001' : fAmount.toFixed(fixedVal);

    return `${formattedAmount} ${resSymbol}`;
};

// export const formatAmount = (amount, withBtc = true, searchType = '', hasContracts = false, needDecimal = false, defFixedVal = 8) => {
// 	let contract = ''
// 	let del = 100000000
// 	let defDecimal = Math.pow(10, !needDecimal ? { getActive }?.activeToken?.decimals || analyticsState?.coinData?.decimals || 0 : defFixedVal)
// 	const decimalAfterPoint = convertToDecimalZeros(defFixedVal)
	
// 	const decimalsMap = {
// 		'trx': 1000000,
// 		'eth': 1000000000000000000,
// 		'btc': 1000000000000000000,
// 	}

// 	if (analyticsState.coinData.family === 'eth' && withBtc != 'clearBtc') {
// 		del = needDecimal ? decimalsMap[searchType] || defDecimal : 1

// 		if(reportState?.activeToken?.decimals && !needDecimal) {
// 			del = Math.pow(10, reportState?.activeToken?.decimals)
// 		}

// 		// Contract
// 		if (hasContracts) {
// 			contract = hasContracts
// 		} else {
// 			contract = analyticsState.coinData.symbol
// 		}

// 		if (amount < 0.00000001 && !hasContracts) {
// 			contract = 'wei'
// 			defFixedVal = 0
// 			amount = amount * defDecimal
// 		}
// 	}

// 	if(withBtc == 'clearFamily') {
// 		contract = analyticsState.coinData.family && analyticsState.coinData.family.toUpperCase()
// 		del = Math.pow(10, 8)
// 	} else {
// 		if (!searchType) contract = reportState.activeToken.symbol && reportState.activeToken.symbol.toUpperCase() || analyticsState.coinData.key.toUpperCase() || 'BTC'
// 		if (!contract) contract = reportState.activeToken.symbol && reportState.activeToken.symbol.toUpperCase() || analyticsState.coinData.key.toUpperCase()
// 	}

// 	const formattedAmount = (fAmount, fDel) => {
// 		// Temp solution for test
// 		return Math.abs(fAmount) <  decimalAfterPoint || (Math.abs(fAmount) / fDel) < decimalAfterPoint ? `< ${decimalAfterPoint.toFixed(8)}` : truncateToEight((fAmount / fDel))
// 		// return Math.abs(fAmount) < 0.00000001 ? (fAmount / fDel).toFixed(Math.floor(Math.abs(Math.log10(fAmount)))).replace(/\.?0+$/, "") : (fAmount / fDel).toFixed(fixedVal)
// 	}

// 	function truncateToEight(number) {
//     return convertIfExponential(parseFloat(number.toFixed(8)))
// 	}

// 	function convertIfExponential(number) {
//     let str = number.toString()
    
//     if (str.indexOf('e') !== -1) {
//         return number.toFixed(20).replace(/\.?0+$/, '')
//     } else {
//         return str
//     }
// 	}

// 	// Temp solution until tomorrow
// 	if(withBtc == 'justAddBtc') {
// 		return `${truncateToEight(amount)} ${contract}`
// 	}
// 	if(withBtc == 'clearFamily') {
// 		return `${formattedAmount(amount, del)} ${contract}`
// 	}
// 	return amount
// 		? `${analyticsState.coinData.family === 'eth'
// 			? formattedAmount(amount, del)
// 			: formattedAmount(amount, del)} ${withBtc
// 			? `${contract}`
// 			: ''}`
// 		: analyticsState.coinData.family === 'eth'
// 			? ''
// 			: 0
// }
export function currencyShareFormatting (amount, decimals) {
	return amount ? Number(amount) * Math.pow(10, decimals ?? analyticsState?.coinData?.decimals ?? 1) : 0
}

export function convertToDecimalZeros(zerosAfterDecimal) {
    var divisor = Math.pow(10, zerosAfterDecimal);
    return  1 / divisor;
}

export const txsAddPlus = (data, threshold = 1000001) => {
    const letter = threshold >= 1000000 ? 'M' : 'K'
  
  if (data >= threshold) {
    const numStr = data?.toString()
    let displayValue = ''

    if (data >= 100000000) {
      displayValue = numStr.slice(0, 3)
    } else if (data >= 10000000) {
      displayValue = numStr.slice(0, 2)
    } else if (data >= 1000000) {
      displayValue = numStr.charAt(0)
    } else if (data >= 100000) {
      displayValue = numStr.slice(0, 3)
    } else if (data >= 10000) {
      displayValue = numStr.slice(0, 2)
    } else {
      displayValue = numStr.charAt(0)
    }

    return data >= threshold ? `${+displayValue}${letter}+` : toComaSeparate(data)
  }
  
  return data
}