var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag",class:[
    { tag__selected: _vm.isSelected },
    {
      'tag__score--not-removable':
        _vm.score !== undefined && _vm.score !== null && !_vm.removable,
      'tag__label-type': _vm.tagLabelType,
    },
  ],style:(`border-color: ${_vm.findColorByTypeScore(
    _vm.score,
  )}; background-color: ${_vm.hex2rgba(_vm.findColorByTypeScore(_vm.score), 0.25)}`),on:{"click":_vm.selectTag}},[(_vm.score !== undefined && _vm.score !== null && _vm.showScore)?_c('div',{staticClass:"mr-2 tag__score",style:(`background-color: ${_vm.findColorByTypeScore(_vm.score)}`)},[_vm._v(" "+_vm._s(_vm.score)+" ")]):_vm._e(),(_vm.tagLabelType && _vm.tagLabelTypeIcon)?_c('gl-icon',{attrs:{"height":16,"name":_vm.tagLabelTypeIconName,"success":_vm.tagLabelTypeIconClass == 'success',"warn":_vm.tagLabelTypeIconClass == 'warn',"width":19}}):_vm._e(),_c('div',{staticClass:"ellipsis",class:[
      { 'mr-1': _vm.tagLabelType, 'ml-1': _vm.score === undefined || _vm.score === null },
    ],style:(`max-width: ${_vm.maxWidthText};`)},[_vm._v(" "+_vm._s(_vm.tag)+" ")]),(_vm.removable)?_c('gl-icon',{staticClass:"tag__close-icon",attrs:{"height":16,"name":"tag-close","width":16},on:{"click":_vm.remove}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }