<template>
  <div class="report-address-info__general">
    <InfoBlock
      label="Blockchain"
      :loading="loading"
    >
      <template #value>
        <GlBlockchainIcon :blockchain-icon="info.coinKey" />
        <span class="report-info-block__value uppercase">{{ info.coinKey?.toUpperCase() }}
        </span>
      </template>
    </InfoBlock>
    <InfoBlock
      v-if="isAssetsShown"
      label="Assets"
      :loading="loading"
    >
      <template #value>
        <GlAvatar
          v-if="selectedToken.address === 'all'"
          :avatar-list="getAvatarsList"
          label-in-list-key="symbol"
          :src-val="selectedToken.icon"
        />
        <GlAvatar
          v-else
          :avatar-list="[selectedToken]"
          label-in-list-key="symbol"
        />
        <span
          v-if="selectedToken.address !== 'all'"
          class="report-info-block__value uppercase"
        >{{ selectedToken.symbol }}
        </span>
      </template>
    </InfoBlock>
    <InfoBlock
      label="Author"
      value="Global Ledger"
    />
    <InfoBlock
      label="Report Generated"
      :loading="loading"
      :value="
        info.createdAt ? formatDate(info.createdAt, 'dd.MM.yyyy HH:mm') : null
      "
    />
  </div>
</template>

<script>
// Components
import InfoBlock from '@/pages/report/components/info/InfoBlock'
import GlBlockchainIcon from '@/components/gl-blockchain-icon'
import GlAvatar from '@/components/gl-avatar.vue'
// utils
import { formatDate } from '@/utils/format-date'
// vuex
import { mapState } from 'vuex'

export default {
  name: 'GeneralInfo',
  components: {
    InfoBlock,
    GlBlockchainIcon,
    GlAvatar,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
    hasAdditionalInfo: {
      type: Boolean,
      default: false,
    },
    selectedToken: {
      type: Object,
      default: () => ({}),
    },
    tokensList: {
      type: Array,
      default: () => [],
    },
    isPdf: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('analytics', ['coinData']),
    isAssetsShown() {
      return this.isPdf && !this.hasAdditionalInfo
    },
    getAvatarsList() {
      return this.tokensList.filter(item => item.address !== 'all')
    },
  },
  methods: { formatDate },
}
</script>
<style>
.report-address-info__general {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}
@media (max-width: 767px) {
  .report-address-info__general {
    grid-template-columns: repeat(2, 1fr);
  }
  .report-address-info__general > *:nth-child(2) {
    order: 3;
  }
}
</style>
